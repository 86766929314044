import * as React from "react";
import { Referential } from "components/entities.typing";
import { DropDown } from "components/common/DropDown/DropDown";
import { Label } from "components/common/ReadOnlyField/Label";
import { ForecastCreateModel, ReferentialSearch } from "./forecastCreate.typing";
import { isValidFormState } from "./forecastCreateHelper";
import {
  BL,
  CLIENT,
  DECNAME,
  FORECAST_PERIOD,
  FORECAST_YEAR,
  ISIB_LABEL,
  ORIGINATING_COUNTRY,
  ORIGINATING_ENTITY,
  PRIMARY_OBLIGOR,
  PRODUCT_LEVEL_1,
  PRODUCT_LEVEL_2,
  PRODUCT_LEVEL_3,
  PRODUCT_LEVEL_4,
  SBL,
  SSBL,
} from "common/forecast.const";
import ReferentialPicker from "components/searchFilters/ReferentialPicker/ReferentialPicker";
import { REFERENTIALS } from "common/referentials.const";
import { InvalidFeedback } from "components/common/ValidationMessage/InvalidFeedback";
import { Link } from "react-router-dom";
import { ROUTES } from "common/routes";
import { Icon } from "components/common/Icon/Icon";
import { FORECAST_PERIOD_LIST, INIT_STATE, updateInitState } from "./ForecastCreate.helper";
import AdjustmentCreateAction from "components/adjustments/adjustmentCreateAction";
import { AdjustmentModal } from "components/adjustments/modals/adjustmentModal";
import { Button } from "components/common/Buttons/Button";

export interface CreateProps {
  years: Referential[];
  forecastYearFilter: string;
  businessProviders: Referential[];
  businessLines: Referential[];
  subBusinessLines: Referential[];
  subSubBusinessLines: Referential[];
  originatingEntities: Referential[];
  originatingCountries: Referential[];
  isIb?: boolean;
  productsL1: Referential[];
  productsL2: Referential[];
  productsL3: Referential[];
  productsL4: Referential[];
  createForecast: (request: ForecastCreateModel) => void;
}

export const ForecastCreateForm: React.FC<CreateProps> = ({
  years,
  forecastYearFilter,
  businessProviders,
  businessLines,
  subBusinessLines,
  subSubBusinessLines,
  originatingEntities,
  originatingCountries,
  isIb,
  productsL1,
  productsL2,
  productsL3,
  productsL4,
  createForecast,
}: CreateProps) => {
  const referentialSearch: ReferentialSearch = {
    forecastYear: forecastYearFilter,
    businessProviders: businessProviders,
    businessLines: businessLines,
    subBusinessLines: subBusinessLines,
    subSubBusinessLines: subSubBusinessLines,
    originatingEntities: originatingEntities,
    originatingCountries: originatingCountries,
    isIb: isIb,
    productsL1: productsL1,
    productsL2: productsL2,
    productsL3: productsL3,
    productsL4: productsL4,
  };
  updateInitState(referentialSearch);
  const [forecastCreateState, setForecastCreateState] = React.useState<ForecastCreateModel>(INIT_STATE);
  const [isReferentialStateValid, setIsReferentialStateValid] = React.useState<boolean>(true);
  const [showModal, setShowModal] = React.useState(false);  

  React.useEffect(() => {
    setIsReferentialStateValid(isValidFormState(forecastCreateState));
  }, [forecastCreateState]);

  const onShowModalAdjustment = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setShowModal(true);
  };
  const onCloseModalAdjustment = () => {
    setShowModal(false);
  };
  const onSumbitModal = () => {
    createForecast(forecastCreateState);
    setShowModal(false);
  };
  const handleHelperCenter = () => {
    const swgtHelpCenter: any = document.querySelector("sgwt-help-center");
    if (swgtHelpCenter && !!swgtHelpCenter.open) {
      swgtHelpCenter.open();
    }
  };

  return (
    <>
      <div className="row">
        <div className="ml-2 mt-3">
          <Link to={ROUTES.SEARCH} style={{ textDecoration: "none" }}>
            <Icon iconName="arrow_back" className="mr-1" /> Go back to main search
          </Link>
        </div>
      </div>
      <div className="row">
        <div className="ml-2 mt-3">
          <h3>Forecasts criteria</h3>
        </div>
      </div>
      <div className="row">
        <div className="col-md-1 form-group">
          <div className="form-group">
            <Label displayLabel={FORECAST_YEAR} htmlFor="inpuState " required={true} />
            <DropDown
              selectedValue={forecastCreateState.ForecastYear.value}
              defaultValue={new Date().getFullYear().toString()}
              emptyItem={false}
              className={"text-center"}
              options={years}
              required={true}
              disabled={forecastYearFilter != null}
              onChange={e => {
                setForecastCreateState({
                  ...forecastCreateState,
                  ForecastYear: {
                    value: e.target.value,
                    isValid: true,
                  },
                });
              }}
            />
          </div>
        </div>
        <div className="col-md-1 form-group">
          <div className="form-group">
            <Label displayLabel={FORECAST_PERIOD} htmlFor="inpuState " required={true} />
            <DropDown className={"text-center"} options={FORECAST_PERIOD_LIST} required={false} disabled={true} />
          </div>
        </div>
        <div className="col-md-3 form-group">
          <ReferentialPicker
            label={CLIENT}
            disabled={businessProviders.length > 0}
            isValid={!!forecastCreateState.BusinessProvider?.selected?.id}
            selected={forecastCreateState.BusinessProvider?.selected}
            onSelect={selected => {
              setForecastCreateState({
                ...forecastCreateState,
                BusinessProvider: {
                  isValid: !!selected.id,
                  selected: selected,
                },
              });
            }}
            includeKeys={true}
            isRequired={true}
            //filerAttributeToComplete="client_ecisactive"
            //valueAttributeToComplete="O"
          />
        </div>
        <div className="col-md-3 form-group">
          <div className="form-group">
            <ReferentialPicker
              label={PRIMARY_OBLIGOR}
              disabled={false}
              isValid={!!forecastCreateState.PrimaryObligor?.selected?.id}
              selected={forecastCreateState.PrimaryObligor?.selected}
              onSelect={selected => {
                setForecastCreateState({
                  ...forecastCreateState,
                  PrimaryObligor: {
                    isValid: !!selected.id,
                    selected: selected,
                  },
                });
              }}
              includeKeys={true}
              isRequired={true}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-2 form-group">
          <ReferentialPicker
            label={BL}
            disabled={businessLines.length > 0}
            isValid={!!forecastCreateState.Bl?.selected?.id}
            selected={forecastCreateState.Bl?.selected}
            onSelect={selected => {
              setForecastCreateState({
                ...forecastCreateState,
                Bl: {
                  isValid: !!selected.id,
                  selected: selected,
                },
              });
            }}
            includeKeys={false}
            isRequired={true}
          />
        </div>
        <div className="col-md-2 form-group">
          <ReferentialPicker
            label={SBL}
            disabled={subBusinessLines.length > 0}
            isValid={!!forecastCreateState.Sbl?.selected?.id}
            selected={forecastCreateState.Sbl?.selected}
            onSelect={selected => {
              setForecastCreateState({
                ...forecastCreateState,
                Sbl: {
                  isValid: !!selected.id,
                  selected: selected,
                },
              });
            }}
            includeKeys={false}
            isRequired={true}
            filteredAttribute={REFERENTIALS[BL]}
            valueAttribute={forecastCreateState.Bl?.selected?.id}
          />
        </div>
        <div className="col-md-2 form-group">
          <ReferentialPicker
            label={SSBL}
            disabled={subSubBusinessLines.length > 0}
            isValid={!!forecastCreateState.Ssbl?.selected?.id}
            selected={forecastCreateState.Ssbl?.selected}
            onSelect={selected => {
              setForecastCreateState({
                ...forecastCreateState,
                Ssbl: {
                  isValid: !!selected.id,
                  selected: selected,
                },
              });
            }}
            includeKeys={false}
            isRequired={true}
            filteredAttribute={REFERENTIALS[SBL]}
            valueAttribute={forecastCreateState.Sbl?.selected?.id}
          />
        </div>
        <div className="col-md-3 form-group">
          <Label displayLabel={ISIB_LABEL} htmlFor="inpuState " required={true} />
          <div className="form-group">
            <input
              type="radio"
              value="true"
              name="IsIb"
              checked={forecastCreateState.IsIb.value === "true"}
              disabled={INIT_STATE.IsIb.value === "false"}
              onChange={() => {
                setForecastCreateState({
                  ...forecastCreateState,
                  IsIb: {
                    isValid: true,
                    value: "true",
                  },
                });
              }}
            />{" "}
            Yes &nbsp;
            <input
              type="radio"
              value="false"
              name="IsIb"
              checked={forecastCreateState.IsIb.value === "false"}
              disabled={INIT_STATE.IsIb.value === "true"}
              onChange={() => {
                setForecastCreateState({
                  ...forecastCreateState,
                  IsIb: {
                    isValid: true,
                    value: "false",
                  },
                });
              }}
            />{" "}
            No
          </div>
          {!forecastCreateState.IsIb?.isValid ? (
            <InvalidFeedback errorMessage="Please check a Is Investment banking (IB) activity" errorType="danger" />
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="row">
        <div className="form-group col-md-2">
          <ReferentialPicker
            label={ORIGINATING_COUNTRY}
            disabled={originatingCountries.length > 0}
            isValid={!!forecastCreateState.OriginatingCountry?.selected?.id}
            selected={forecastCreateState.OriginatingCountry?.selected}
            onSelect={selected => {
              setForecastCreateState({
                ...forecastCreateState,
                OriginatingCountry: {
                  isValid: !!selected.id,
                  selected: selected,
                },
              });
            }}
            includeKeys={false}
            isRequired={true}
          />
        </div>
        <div className="form-group col-md-2">
          <div className="form-group">
            <ReferentialPicker
              label={ORIGINATING_ENTITY}
              disabled={originatingEntities.length > 0}
              isValid={!!forecastCreateState.OriginatingEntity?.selected?.id}
              selected={forecastCreateState.OriginatingEntity?.selected}
              onSelect={selected => {
                setForecastCreateState({
                  ...forecastCreateState,
                  OriginatingEntity: {
                    isValid: !!selected.id,
                    selected: selected,
                  },
                });
              }}
              includeKeys={true}
              isRequired={true}
            />
          </div>
        </div>
        <div className="form-group col-md-2">
          <div className="form-group">
            <ReferentialPicker
              label={DECNAME}
              disabled={false}
              isValid={true}
              selected={forecastCreateState.DecName?.selected}
              onSelect={selected => {
                setForecastCreateState({
                  ...forecastCreateState,
                  DecName: {
                    isValid: !!selected.id,
                    selected: selected,
                  },
                });
              }}
              includeKeys={true}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="form-group col-md-2">
          <div className="form-group">
            <ReferentialPicker
              label={PRODUCT_LEVEL_1}
              disabled={productsL1.length > 0}
              isValid={!!forecastCreateState.ProductLevel1?.selected?.id}
              selected={forecastCreateState.ProductLevel1?.selected}
              onSelect={selected => {
                setForecastCreateState({
                  ...forecastCreateState,
                  ProductLevel1: {
                    isValid: !!selected.id,
                    selected: selected,
                  },
                });
              }}
              includeKeys={false}
              isRequired={true}
            />
          </div>
        </div>
        <div className="form-group col-md-2">
          <div className="form-group">
            <ReferentialPicker
              label={PRODUCT_LEVEL_2}
              disabled={productsL2.length > 0}
              isValid={!!forecastCreateState.ProductLevel2?.selected?.id}
              selected={forecastCreateState.ProductLevel2?.selected}
              onSelect={selected => {
                setForecastCreateState({
                  ...forecastCreateState,
                  ProductLevel2: {
                    isValid: !!selected.id,
                    selected: selected,
                  },
                });
              }}
              includeKeys={false}
              isRequired={true}
              filteredAttribute={REFERENTIALS[PRODUCT_LEVEL_1]}
              valueAttribute={forecastCreateState.ProductLevel1?.selected?.id}
            />
          </div>
        </div>
        <div className="form-group col-md-2">
          <div className="form-group">
            <ReferentialPicker
              label={PRODUCT_LEVEL_3}
              disabled={productsL3.length > 0}
              isValid={!!forecastCreateState.ProductLevel3?.selected?.id}
              selected={forecastCreateState.ProductLevel3?.selected}
              onSelect={selected => {
                setForecastCreateState({
                  ...forecastCreateState,
                  ProductLevel3: {
                    isValid: !!selected.id,
                    selected: selected,
                  },
                });
              }}
              includeKeys={true}
              isRequired={true}
              filteredAttribute={REFERENTIALS[PRODUCT_LEVEL_2]}
              valueAttribute={forecastCreateState.ProductLevel2?.selected?.id}
            />
          </div>
        </div>
        <div className="form-group col-md-2">
          <div className="form-group">
            <ReferentialPicker
              label={PRODUCT_LEVEL_4}
              disabled={productsL4.length > 0}
              isValid={!!forecastCreateState.ProductLevel4?.selected?.id}
              selected={forecastCreateState.ProductLevel4?.selected}
              onSelect={selected => {
                setForecastCreateState({
                  ...forecastCreateState,
                  ProductLevel4: {
                    isValid: !!selected.id,
                    selected: selected,
                  },
                });
              }}
              includeKeys={false}
              isRequired={true}
              filteredAttribute={REFERENTIALS[PRODUCT_LEVEL_3]}
              valueAttribute={forecastCreateState.ProductLevel3?.selected?.label}
            />
          </div>
        </div>
        <div className="col-md-1 form-group">
          <div className="form-group"></div>
          <div className="form-group">
            <Button type="button" className="default">
              <Icon
                iconName="help_outline"
                className="mr-1"
                title="How to choose products?"
                onClick={handleHelperCenter}
              />
            </Button>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="form-group col-md-3">
          <div className="form-group">
            <label>
              <i>* Mandatory criteria</i>
            </label>
          </div>
        </div>
      </div>
      <AdjustmentModal
        show={showModal}
        onClose={onCloseModalAdjustment}
        onSubmit={onSumbitModal}
        forecastCreateModel={forecastCreateState}
      />
      <div className="row">
        <div className="ml-2 btn-group float-right" role="group">
          <AdjustmentCreateAction display={isReferentialStateValid} onClick={onShowModalAdjustment} />
        </div>
      </div>
    </>
  );
};
