import { ROUTES } from "common/routes";
import { LinkButton } from "components/common/Buttons/LinkButton";
import { Icon } from "components/common/Icon/Icon";
import * as React from "react";
import { generateUriById } from "utils/uris/generateUri";

export interface Props {
  projectionId: number;
}

export const ForecastEditAction: React.FC<Props> = ({ projectionId }: Props) => {
  const uri = generateUriById(ROUTES.PROJECTION_DETAIL, projectionId);
  return (
    <LinkButton className="btn btn-sm btn-primary btn-icon-text" label="Edit Forecast" target="_blank" to={uri}>
      <Icon iconName="edit" size="sm" />
    </LinkButton>
  );
};
