import appLogo from "assets/images/app-logo.png";
import sgLogo from "assets/images/sg-logo.png";
import * as React from "react";
import { HeaderMenu } from "components/layout/Header/HeaderMenu";
import { SgHeader } from "components/layout/Header/SgHeader";
import { getEnvironment } from "common/environment";
import { IconCaretDown } from "components/common/Icon/Icon";
import { SGMARKETS_URL } from "common/routes";

export const Header: React.FunctionComponent = () => {
  const environment = getEnvironment();
  return (
    <>
      <nav className="navbar navbar-expand-xl navbar-light bg-white shadow-lg">
        <div className="d-flex">
          <a href={SGMARKETS_URL} className="navbar-brand d-flex align-items-center">
            <img src={sgLogo} alt="Logo SG" height="32" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            Menu
            <i className="icon">
              <IconCaretDown />
            </i>
          </button>
        </div>
        <div className="d-flex align-items-center order-xl-1">
          <SgHeader environment={environment} />
          <a className="d-none d-md-block overflow-visible ml-3">
            <img src={appLogo} />
          </a>
        </div>
        <div className="collapse navbar-collapse" id="navbarNav">
          <HeaderMenu />
        </div>
      </nav>
    </>
  );
};
