import * as React from "react";
import { Loading } from "components/common/Loading/Loading";
import { sgwtConnect } from "common/auth/sgwtConnect";
import { useAuthenticationContext } from "hooks/contexts/useAuthenticationContext";
import { CerberusApi } from "api/cerberus.api";
import { PageForbidden } from "components/common/PageForbidden";
import { PageNotAuthorized } from "components/common/PageNotAuthorized";
import { useState } from "react";
import { CerberusUserProfileDto, UserClaim } from "api/cerberus.api.typing";

// eslint-disable-next-line react/prop-types
export const Authentication: React.FC = ({ children }) => {
  const { authState: authentication } = useAuthenticationContext();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [userClaims, setUserClaims] = useState<UserClaim[]>([]);
  const [isAuthorized, setIsAuthorized] = useState<boolean>(false);

  React.useEffect(() => {
    if(authentication.connectedUser){
      CerberusApi.getCerberusUserProfile(authentication.connectedUser).then(result => {
        const userProfile = result as CerberusUserProfileDto;
        if (userProfile){
          setIsLoading(false);
          setIsAuthorized(true);
          setUserClaims(userProfile.validatedRequestCProfit?.claims ?? []);
          return;
        }
        setIsLoading(false);
        setIsAuthorized((result as Response)?.status !== 401);
      },
      err => {
        setIsLoading(false);
        setIsAuthorized((err as Response)?.status !== 401);
      })
    }
  }, [authentication.connectedUser]);

 const doesUserHaveClaims = (): boolean => {
  return userClaims.find(x => x.domainId === "forecast") !== undefined;
}

  // This is used by the account center :
  // https://sgithub.fr.world.socgen/DT-Widgets/sgwt-widgets/blob/master/src/sgwt-account-center/README.md#authentication
  window.sgwtConnect = sgwtConnect;

  return (
    <>
      {
        (!authentication.loggedIn || isLoading) ? 
          <Loading/>
         : (isAuthorized) ?
          (doesUserHaveClaims()) ?
            children
            : <PageForbidden />
          : <PageNotAuthorized />
      }
    </>
  );
};
