import * as React from "react";
import { BoldAmount } from "../Common/BoldAmount";

export interface ICalculatedForecastTotalProps {
  totalNbi: number;
  totalRwa: number;
  totalLabel: string;
}

export const CalculatedForecastTotal: React.FC<ICalculatedForecastTotalProps> = ({
  totalNbi,
  totalRwa,
  totalLabel,
}: ICalculatedForecastTotalProps) => {
  return (
    <tr key="totalCalculatedForecastRox" className="table-success">
      <td>
        <b>{totalLabel}</b>
      </td>
      <td style={{ textAlign: "right" }}>
        <BoldAmount amount={totalNbi} />
      </td>
      <td style={{ textAlign: "right" }}>
        <BoldAmount amount={totalRwa} />
      </td>
      <td />
      <td />
    </tr>
  );
};
