import * as React from "react";
import { NavLink } from "react-router-dom";

export const PageForbidden: React.FC = () => {
  return (
    <div className="page-404 pt-5">
      <section className="section-2 mt-5 vh-100">
        <div className="container-fluid">
          <div className="container main-wrapper">
            <div className="row">
              <div className="col-lg-2 bg-white pt-5 pb-4 py-lg-0">
                <span className="d-block d-md-none text-outline-socgen text-primary-alt font-weight-medium"
                  style={{ fontSize: '56px', fontFamily: 'Inter' }}>
                  403
              </span>
                <span className="d-none d-md-block d-lg-none text-outline-socgen text-primary-alt font-weight-medium"
                  style={{ lineHeight: 1.2, fontSize: '56px', fontFamily: 'Inter' }}>
                  403
              </span>
                <span className="mt-n2 d-none d-lg-inline-block d-xl-none display-2 text-outline-socgen text-primary-alt font-weight-medium">
                  403
              </span>
                <span className="mt-n3 d-none d-xl-block display-1 text-outline-socgen text-primary-alt font-weight-medium"
                  style={{ fontSize: '96px', overflow: 'hidden' }}>
                  403
              </span>
              </div>
              <div className="col-lg-5  bg-white d-flex flex-column justify-content-between">
                <div>
                  <h1 className="d-none d-lg-block display-2 font-weight-medium line-height-1 pt-0 pb-3">
                    Forbidden
                  </h1>
                  <h1 className="d-lg-none display-3 font-weight-medium line-height-1 py-2">
                    Forbidden
                  </h1>

                  {/* Mobile */}
                  <p className="d-lg-none mb-5 h3 font-weight-medium text-secondary line-height-1">
                    You do not have the rights to access this module
                  </p>
                  {/* Desktop */}
                  <p className="d-none d-lg-block d-xxl-none display-3 font-weight-medium text-secondary w-75 line-height-1">
                    You do not have the rights to access this module
                    
                  </p>

                  {/* XXL */}
                  <p className="d-none d-xxl-block display-3 font-weight-medium text-secondary w-75 line-height-1">
                    You do not have the rights to access this module
                  </p>
                </div>
                <div className="mb-5 d-none d-md-flex">
                  <NavLink className="btn btn-xl btn-primary" to="/">
                    Go back to homepage
                  </NavLink>
                  <a className="btn btn-xl btn-flat-secondary ml-1" href={`mailto:gbsu-cld-dci-cprofit-collexplore-support.world@sgcib.com`}>
                    Contact us
                  </a>
                </div>
                <div className="d-block d-md-none">
                  <NavLink className="btn btn-xl btn-primary btn-block mb-1" to="/">
                    Go back to homepage
                  </NavLink>
                  <a className="btn btn-xl btn-flat-secondary ml-1 btn-block" href={`mailto:gbsu-cld-dci-cprofit-collexplore-support.world@sgcib.com`}>
                    Contact us
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};