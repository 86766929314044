import referentialApi from "api/referentials.api";
import { ReferentialsKeyValues, ReferentialsValues } from "api/referentials.api.typings";
import { REFERENTIALS } from "common/referentials.const";
import * as React from "react";
import { BL, ISIB_ALL, PRODUCT_LEVEL_1, PRODUCT_LEVEL_2, PRODUCT_LEVEL_4, SBL } from "common/forecast.const";
import { ForecastCreateForm } from "../forecastCreateForm";
import { LicenseManager } from "ag-grid-enterprise";
import { agGridLicense } from "utils/ag-grid-license";
import { SearchFiltersModel } from "components/searchFilters/Filters/searchFilters.typings";
import { RouteComponentProps } from "react-router-dom";
import { mapRequestToForecastCreateForm } from "../forecast.mapper";
import { ForecastCreateModel } from "../forecastCreate.typing";
import { ForecastApi } from "api/forecasts.api";
import { generateUriById } from "utils/uris/generateUri";
import { ROUTES } from "common/routes";

LicenseManager.setLicenseKey(agGridLicense);

const ForecastCreateContainer: React.FC<RouteComponentProps> = (props: RouteComponentProps) => {
  const [forecastYearFilter, setForecastYearFilter] = React.useState(null);
  const [businessLines, setBusinessLines] = React.useState<ReferentialsValues>(null);
  const [subBusinessLines, setSubBusinessLines] = React.useState<ReferentialsValues>();
  const [subSubBusinessLines, setSubSubBusinessLines] = React.useState<ReferentialsValues>();
  const [originatingEntities, SetOriginatingEntities] = React.useState<ReferentialsKeyValues>(null);
  const [originatingCountries, setOriginatingCountries] = React.useState<ReferentialsValues>(null);
  const [businessProviders, setBusinessProvider] = React.useState<ReferentialsKeyValues>(null);
  const [productsL1, setProductsL1] = React.useState<ReferentialsValues>(null);
  const [productsL2, setProductsL2] = React.useState<ReferentialsValues>(null);
  const [productsL3, setProductsL3] = React.useState<ReferentialsKeyValues>(null);
  const [productsL4, setProductsL4] = React.useState<ReferentialsValues>(null);
  const [isIb, setIsIb] = React.useState<boolean>(null);
  const searchFiltersModel = props.location.state as SearchFiltersModel;
  // disabled temporary business provider default selection
  searchFiltersModel.BusinessProvider = {
    dropdownState: { selected: null, isValid: false },
    clientId: undefined,
  };

  React.useEffect(() => {
    (async () => {
      if (searchFiltersModel !== null) {
        setForecastYearFilter(searchFiltersModel.ForecastYear.value);

        setIsIb(searchFiltersModel.IsIb.value === ISIB_ALL? null : Boolean(JSON.parse(searchFiltersModel.IsIb.value)));
        if (searchFiltersModel.BusinessProvider.dropdownState.selected != null) {
          setBusinessProvider({
            ...businessProviders,
            keysValues: [
              {
                key: searchFiltersModel.BusinessProvider.dropdownState.selected.id,
                value: searchFiltersModel.BusinessProvider.dropdownState.selected.label,
              },
            ],
          });
        }
        const ssbl = searchFiltersModel.Ssbl.selected?.label;
        if (searchFiltersModel.Ssbl.selected !== null) {
          const filter = `fpv_ssbl:${ssbl}`;
          setSubSubBusinessLines({ values: [`${ssbl}`] });
          referentialApi.getReferentialsValues(REFERENTIALS[BL], filter).then(result => setBusinessLines(result));
          referentialApi.getReferentialsValues(REFERENTIALS[SBL], filter).then(result => setSubBusinessLines(result));
        }
        if (searchFiltersModel.OriginatingCountry.selected !== null) {
          setOriginatingCountries({ values: [`${searchFiltersModel.OriginatingCountry.selected.label}`] });
        }
        if (searchFiltersModel.OriginatingEntity.selected != null) {
          SetOriginatingEntities({
            keysValues: [
              {
                key: searchFiltersModel.OriginatingEntity.selected.id,
                value: searchFiltersModel.OriginatingEntity.selected.label,
              },
            ],
          });
        }
        if (searchFiltersModel.ProductLevel.selected != null) {
          const filterProductL3 = `product_l3:${searchFiltersModel.ProductLevel.selected.label}`;
          setProductsL3({
            keysValues: [
              {
                key: searchFiltersModel.ProductLevel.selected.id,
                value: searchFiltersModel.ProductLevel.selected.label,
              },
            ],
          });
          referentialApi
            .getReferentialsValues(REFERENTIALS[PRODUCT_LEVEL_2], filterProductL3)
            .then(result => setProductsL2(result));
          referentialApi
            .getReferentialsValues(REFERENTIALS[PRODUCT_LEVEL_1], filterProductL3)
            .then(result => setProductsL1(result));
          referentialApi
            .getReferentialsValues(REFERENTIALS[PRODUCT_LEVEL_4], filterProductL3)
            .then(result => setProductsL4(result));
        }
      }
    })();
  }, []);

  const getBusinessProvider = businessProviders
    ? businessProviders.keysValues.map(element => {
        return { id: element.key, label: element.value };
      })
    : [];
  const getOriginatingEntities = originatingEntities
    ? originatingEntities.keysValues.map(element => {
        return { id: element.key, label: element.value };
      })
    : [];
  const getProductL3 = productsL3
    ? productsL3.keysValues.map(element => {
        return { id: element.key, label: element.value };
      })
    : [];
  const getBusinessLines = mapValues(businessLines);
  const getSubBusinessLines = mapValues(subBusinessLines);
  const getSubSubBusinessLines = mapValues(subSubBusinessLines);
  const getOriginatingCountries = mapValues(originatingCountries);

  const getProductL1 = mapValues(productsL1);
  const getProductL2 = mapValues(productsL2);

  const getProductL4 = mapValues(productsL4);

  const getYearsFromCampaigns = forecastYearFilter
    ? [{ id: forecastYearFilter.toString(), label: forecastYearFilter.toString() }]
    : [];

  const createForecast = (request: ForecastCreateModel) => {
    ForecastApi.createForecast(mapRequestToForecastCreateForm(request)).then(result => {
      props.history.push(generateUriById(ROUTES.PROJECTION_DETAIL, result.id));
    });
  };

  return (
    <div className="container-fluid">
      <ForecastCreateForm
        years={getYearsFromCampaigns}
        forecastYearFilter={forecastYearFilter}
        businessProviders={getBusinessProvider}
        businessLines={getBusinessLines}
        subBusinessLines={getSubBusinessLines}
        subSubBusinessLines={getSubSubBusinessLines}
        originatingEntities={getOriginatingEntities}
        originatingCountries={getOriginatingCountries}
        isIb={isIb}
        productsL1={getProductL1}
        productsL2={getProductL2}
        productsL3={getProductL3}
        productsL4={getProductL4}
        createForecast={createForecast}
      />
    </div>
  );
};

const mapValues = (elementList: ReferentialsValues): { id: string; label: string }[] => {
  return elementList
    ? elementList.values.map(element => {
        return { id: element, label: element };
      })
    : [];
};

export default ForecastCreateContainer;
