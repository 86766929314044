import * as React from "react";
import { Adjustment } from "components/entities.typing";
import { Button } from "components/common/Buttons/Button";
import { noop } from "lodash";
import { Icon } from "components/common/Icon/Icon";
import { decimalFormatter } from "common/helpers";
import { AdjustmentApi } from "api/adjustments.api";
export interface IAdjustmentListRowProps {
  adjustment: Adjustment;
  campaignStatus: string;
  onDeleteAdjustment?: (id: number) => void;
  onEditAdjustment: (id: number) => void;
}
export const AdjustmentListRow: React.FC<IAdjustmentListRowProps> = ({
  adjustment: { id, nbiAdjustment, rwaAdjustment, createdBy, comment },
  campaignStatus,
  onDeleteAdjustment,
  onEditAdjustment,
}: IAdjustmentListRowProps) => {
  const onDownloadJustification = (id: number) => {
    AdjustmentApi.downloadAdjustmentJustification(id);
  };
  return (
    <tr key={id}>
      <td>{id}</td>
      <td>{createdBy}</td>
      <td style={{ textAlign: "right" }}>{`€${decimalFormatter(nbiAdjustment, 2)}`}</td>
      <td style={{ textAlign: "right" }}>{`€${decimalFormatter(rwaAdjustment, 2)}`}</td>
      <td />
      <td />
      <td>
        <div>
          <Button type="button" className="btn btn-secondary btn-icon-text mr-2" onClick={noop} disabled={true}>
            <Icon iconName="pageview" size="md" />
            Open
          </Button>
          <Button type="button" className="btn btn-secondary btn-icon-text" onClick={() => onDownloadJustification(id)}>
            <Icon iconName="get_app" size="md" />
            Download
          </Button>
        </div>
      </td>
      <td>
        <div>
          <Button
            type="button"
            className="btn btn-primary  btn-icon-text mr-2"
            onClick={() => onEditAdjustment(id)}
            disabled={campaignStatus !== "Opened"}
          >
            <Icon iconName="edit" size="md" />
            Edit
          </Button>
          <Button
            type="button"
            className="btn btn-mars  btn-icon-text"
            onClick={() => onDeleteAdjustment(id)}
            disabled={campaignStatus !== "Opened"}
          >
            <Icon iconName="delete" size="md" />
            Delete
          </Button>
        </div>
      </td>
      <td>{comment}</td>
    </tr>
  );
};
