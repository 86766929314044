import * as React from "react";
import styles from "components/common/Loading/Loading.scss";
import classNames from "classnames";
import { Spinner } from "../Spinner/Spinner";

export interface Props {
  isVisible?: boolean;
  message?: string;
}
export const Loading: React.FunctionComponent<Props> = ({ isVisible = true, message = "Loading..." }: Props) =>
  isVisible ? (
    <div className="row">
      <div className={classNames("col-md-12 col-12 no-padding", styles?.featureLoader)}>
        <span className={classNames(styles?.loadingContainer)}>
          <Spinner />
          {message}
        </span>
      </div>
    </div>
  ) : null;
