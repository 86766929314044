import * as React from "react";

interface Props {
  id: string;
  label?: string;
  className?: string;
  labelClassName?: string;
  children: React.ReactNode;
}

export const FormField: React.FunctionComponent<Props> = ({
  id,
  label,
  children = null,
  className = "",
  labelClassName = "text-secondary mb-0",
}: Props) => (
  <div className={`form-group ${className}`}>
    <label className={labelClassName} htmlFor={id}>
      {label}
    </label>
    <span id={id}>{children}</span>
  </div>
);
