import { Adjustment, AdjustmentCreateDto } from "components/entities.typing";
import { isEmpty } from "lodash";
import { AdjustmentModelState } from "./adjustementModal.typing";

export const mapState = (
  adjustment: Adjustment,
  currentTotalNbiForecast: number,
  currentTotalRwaForecast: number,
  rwaQ1: number,
  rwaQ2: number,
  rwaQ3: number
): AdjustmentModelState => {
  return {
    justificationFile: {
      isValid: true,
      value: adjustment?.justificationFile,
    },
    nbi: {
      isValid: !!adjustment?.nbiAdjustment,
      value: adjustment?.nbiAdjustment,
    },
    rwa: {
      isValid: !!adjustment?.rwaAdjustment,
      value: adjustment?.rwaAdjustment,
    },
    currentTotalNbiForecast: {
      isValid: true,
      value: currentTotalNbiForecast,
    },
    currentTotalRwaForecast: {
      isValid: true,
      value: currentTotalRwaForecast,
    },
    newTotalNbiForecast: {
      isValid: true,
      value: currentTotalNbiForecast + (adjustment?.nbiAdjustment ?? 0),
    },
    newTotalRwaForecast: {
      isValid: true,
      value: currentTotalRwaForecast + (adjustment?.rwaAdjustment ?? 0),
    },
    rwaQ1: {
      isValid: true,
      value: null
    },
    rwaQ2: {
      isValid: true,
      value: null
    },
    rwaQ3: {
      isValid: true,
      value: null
    },
    rwaQ4: {
      isValid: true,
      value: getRwaQ4(currentTotalRwaForecast + (adjustment?.rwaAdjustment ?? 0), rwaQ1,rwaQ2, rwaQ3)
    },
    comment: {
      isValid: isCommentValid(adjustment?.comment, adjustment?.rwaAdjustment, adjustment?.nbiAdjustment),
      value: adjustment?.comment,
    },
    id: adjustment.id,
    justificationFileName: adjustment.justificationFileName,
  };
};

export const mapAdjustementFromState = (adjustmentModelState: AdjustmentModelState): AdjustmentCreateDto => {
  return {
    id: adjustmentModelState.id,
    nbiAdjustment: adjustmentModelState.nbi.value ?? 0,
    rwaAdjustment: adjustmentModelState.rwa.value ?? 0,
    comment: adjustmentModelState.comment.value,
    justificationFile: adjustmentModelState.justificationFile.value,
  };
};

export const isModalFormValid = (adjustmentState: AdjustmentModelState): boolean => {
  return ((adjustmentState.nbi.isValid || adjustmentState.rwa.isValid) && adjustmentState.newTotalRwaForecast.isValid) && (adjustmentState.comment?.isValid ?? true);
};

export const isCommentValid = (comment: string, nbiAdjustment: number, rwaAdjustment: number) => {
  return (!isEmpty(comment) || !(nbiAdjustment >= 1000000 || rwaAdjustment >= 1000000)) && (comment?.length ?? 0) <= 500;
};

export const getNewTotalRwaForecast = (currentTotalRwaForecast: number, rwaAdjustment?: number): number => {
  return (currentTotalRwaForecast ?? 0) + (rwaAdjustment ?? 0);
}

export const getRwaQ4 = (rwaForecast: number, rwaQ1: number, rwaQ2: number, rwaQ3: number): number => {
  return rwaForecast * 4 - (rwaQ1 + rwaQ2 + rwaQ3);
}
