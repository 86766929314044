import * as React from "react";

export interface Props {
  columns: string[];
  rowClassName?: string;
}

export const TableBody: React.FC<Props> = ({ columns, rowClassName }: Props) => {
  return (
    <tbody>
      <tr className={rowClassName}>
        {columns.map((element, index) => {
          if (!isNaN(parseFloat(element))) {
            return (
              <th key={index} scope="col" className="text-right">
                <h5>€ {element}</h5>
              </th>
            );
          } else {
            return (
              <th key={index} scope="col" className="text-left">
                <h5>{element}</h5>
              </th>
            );
          }
        })}
      </tr>
    </tbody>
  );
};
