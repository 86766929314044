import { decimalFormatter } from "common/helpers";
import { Forecast } from "components/entities.typing";
import * as React from "react";
import { TableBody } from "../../common/Table/tableBody";
import { TableHeader } from "../../common/Table/tableHeader";

interface Props {
  forecastsList: Forecast[];
}

const TotalBottomData: React.FC<Props> = ({ forecastsList }: Props) => {
  const [totalNBI, setTotalNbi] = React.useState(null);
  const [totalRWA, setTotalRwa] = React.useState(null);
  const [totalIBNBI, setTotalIbNbi] = React.useState(null);
  const [totalNoIBNBI, setTotalNoIbNbi] = React.useState(null);
  React.useEffect(() => {
    if (forecastsList) {
      setTotalNbi(forecastsList.reduce((total, currentItem) => total + currentItem.nbiForecast, 0));
      setTotalRwa(forecastsList.reduce((total, currentItem) => total + currentItem.rwaForecast, 0));
      setTotalIbNbi(
        forecastsList
          .filter(({ isIb }) => isIb === "1")
          ?.reduce((total, currentItem) => total + currentItem.nbiForecast, 0)
      );
      setTotalNoIbNbi(
        forecastsList
          .filter(({ isIb }) => isIb === "0")
          ?.reduce((total, currentItem) => total + currentItem.nbiForecast, 0)
      );
    }
  }, [forecastsList]);

  return (
    forecastsList &&
    forecastsList.length > 0 && (
      <>
        <h4>Total results</h4>
        <table className="table table-hover table-sm" width="30px">
          <TableHeader columns={["", "NBI", "o/w NBI no IB", "o/w NBI IB", "RWA"]} numericColumnsIndex={[1, 2, 3, 4]} />
          <TableBody
            rowClassName="table-success"
            columns={[
              "Total forecast",
              decimalFormatter(totalNBI, 2),
              decimalFormatter(totalNoIBNBI, 2),
              decimalFormatter(totalIBNBI, 2),
              decimalFormatter(totalRWA, 2),
            ]}
          />
        </table>
      </>
    )
  );
};
export default TotalBottomData;
