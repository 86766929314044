import * as React from "react";
import { BoldAmount } from "../Common/BoldAmount";

export interface IForecastDetailsTotalRowProps {
  totalNbi: number;
  totalRwa: number;
  totalLabel: string;
}

export const ForecastDetailsTotalRow: React.FC<IForecastDetailsTotalRowProps> = ({
  totalNbi,
  totalRwa,
  totalLabel,
}: IForecastDetailsTotalRowProps) => {
  return (
    <tr key="totalRow" className="table-success">
      <td>
        <b>{totalLabel}</b>
      </td>
      <td style={{ textAlign: "right" }}>
        <BoldAmount amount={totalNbi} />
      </td>
      <td style={{ textAlign: "right" }}>
        <BoldAmount amount={totalRwa} />
      </td>
    </tr>
  );
};
