import * as React from "react";

// Applies useContext but with a checks that the Provider exist.
// Default value for context should be 'undefined' : const obj = useContext(undefined);
export const useSafeContext = <T>(contextObj: React.Context<T>, contextName: string): T => {
  const context = React.useContext(contextObj);
  if (!context) {
    throw new Error(`${contextName} must be used in a component within a Provider.`);
  }
  return context;
};
