import { decimalFormatter } from "common/helpers";
import {
  COMMENT,
  CURRENT_TOTAL_NBI_FORECAST,
  CURRENT_TOTAL_RWA_FORECAST,
  JUSTIFICATION,
  NBI_ADJUSTMENT,
  NEW_TOTAL_NBI_FORECAST,
  NEW_TOTAL_RWA_FORECAST,
  RWA_ADJUSTMENT,
  RWA_Q1,
  RWA_Q2,
  RWA_Q3,
  RWA_Q4,
} from "components/adjustments/modals/adjustment.modal.const";
import { FileUploader } from "components/common/FileUploader/FileUploader";
import { Modal } from "components/common/Modal/Modal";
import { FormField } from "components/common/ReadOnlyField/FormField";
import { Label } from "components/common/ReadOnlyField/Label";
import { Adjustment, AdjustmentCreateDto } from "components/entities.typing";
import { isEmpty, isEqual } from "lodash";
import React = require("react");
import { AdjustmentModelState } from "./adjustementModal.typing";
import { getNewTotalRwaForecast, isCommentValid, isModalFormValid, mapAdjustementFromState, mapState } from "./AdjustmentModal.helper";

export interface Props {
  showModal: boolean;
  onClose: () => void;
  onSubmit: (adjustmentDto: AdjustmentCreateDto, creationMode: boolean) => void;
  adjustment?: Adjustment;
  currentTotalNbiForecast: number;
  currentTotalRwaForecast: number;
  rwaQ1: number;
  rwaQ2: number;
  rwaQ3: number;
  rwaQ4: number;
}

const DEFAULT_STATE = {
  justificationFile: {
    isValid: true,
    value: null,
  },
  JustificationFileName: null,
  nbi: {
    isValid: false,
    value: null,
  },
  rwa: {
    isValid: false,
    value: null,
  },
  currentTotalNbiForecast: {
    isValid: true,
  },
  currentTotalRwaForecast: {
    isValid: true,
  },
  newTotalNbiForecast: {
    isValid: true,
  },
  newTotalRwaForecast: {
    isValid: true,
  },
  rwaQ1: {
    isValid: true
  },
  rwaQ2: {
    isValid: true
  },
  rwaQ3: {
    isValid: true
  },
  rwaQ4: {
    isValid: true
  },
  comment: {
    isValid: true,
    value: ''
  },
  adjustmentId: null,
};
export const AdjustmentModal: React.FC<Props> = ({
  showModal,
  onClose,
  onSubmit,
  adjustment,
  currentTotalNbiForecast,
  currentTotalRwaForecast,
  rwaQ1,
  rwaQ2,
  rwaQ3,
  rwaQ4
}: Props) => {
  const [isModalValid, setIsModalValid] = React.useState<boolean>(false);
  const [adjustmentState, setAdjustmentCreateState] = React.useState<AdjustmentModelState>(DEFAULT_STATE);
  const [creationMode, setCreationMode] = React.useState<boolean>(true);

  const initModalState = React.useMemo(
    () => (adjustment ? mapState(adjustment, currentTotalNbiForecast, currentTotalRwaForecast, rwaQ1, rwaQ2, rwaQ3) : null),
    [adjustment]
  );

  React.useEffect(() => {
    if (adjustment) {
      setAdjustmentCreateState(mapState(adjustment, currentTotalNbiForecast, currentTotalRwaForecast, rwaQ1, rwaQ2, rwaQ3));
      setCreationMode(false);
    } else {
      setCreationMode(true);
    }
  }, [adjustment]);

  React.useEffect(() => {
    setIsModalValid(!isEqual(initModalState, adjustmentState) && isModalFormValid(adjustmentState));
  }, [adjustmentState, initModalState]);

  const handleCloseModal = () => {
    setAdjustmentCreateState(DEFAULT_STATE);
    onClose();
  };

  const handleOnSumbit = () => {
    onSubmit(mapAdjustementFromState(adjustmentState), creationMode);
    setAdjustmentCreateState(DEFAULT_STATE);
  };

  const onSelectFileChange = (file: File) => {
    setAdjustmentCreateState({
      ...adjustmentState,
      justificationFile: {
        isValid: true,
        value: file,
      },
    });
  };

  const onFileRemove = () => {
    setAdjustmentCreateState({
      ...adjustmentState,
      justificationFile: {
        isValid: false,
        value: null,
      },
    });
  };

  return (
    <Modal
      size="xl"
      title={`${creationMode ? "Create Adjustment" : "Edit Adjustment"}`}
      cancelLabel="Cancel"
      submitLabel="Confirm"
      show={showModal}
      onClose={handleCloseModal}
      onCancel={handleCloseModal}
      onSubmit={handleOnSumbit}
      isSubmitValid={isModalValid}
    >
      <div className="row">
        <div className="col-md-3 form-group">
          <div className="form-group">
            <Label displayLabel={CURRENT_TOTAL_NBI_FORECAST} htmlFor="inpuState " required={false} />
            <input
              className="form-control"
              disabled={true}
              placeholder={"€123.00"}
              value={`€${decimalFormatter(currentTotalNbiForecast, 2)}`}
            />
          </div>
        </div>
        <div className="col-md-3 form-group">
          <div className="form-group">
            <Label displayLabel={NBI_ADJUSTMENT} htmlFor="inpuState " required={false} />
            <input
              type="number"
              className="form-control"
              placeholder={"Please enter the NBI amount"}
              onChange={e =>
                setAdjustmentCreateState({
                  ...adjustmentState,
                  nbi: {
                    isValid: !isEmpty(e.target.value),
                    value: !isEmpty(e.target.value) ? parseFloat(e.target.value) : null,
                  },
                  newTotalNbiForecast: {
                    isValid: true,
                    value: !isEmpty(e.target.value)
                      ? (currentTotalNbiForecast ?? 0) + parseFloat(e.target.value)
                      : currentTotalNbiForecast,
                  },
                  comment: {
                    isValid: isCommentValid(adjustmentState?.comment?.value, (!isEmpty(e.target.value) ? parseFloat(e.target.value) : null), adjustmentState?.rwa?.value),
                    value: adjustmentState?.comment?.value ?? ""
                  },
                })
              }
              value={adjustmentState?.nbi?.value ?? ""}
            />
          </div>
        </div>
        <div className="col-md-3 form-group">
          <div className="form-group">
            <FormField id={NEW_TOTAL_NBI_FORECAST} label={NEW_TOTAL_NBI_FORECAST}>
              <input
                className={`form-control`}
                placeholder={"€123.00"}
                disabled={true}
                required={false}
                value={`€${decimalFormatter(
                  adjustmentState?.newTotalNbiForecast?.value ?? currentTotalNbiForecast ?? 0,
                  2
                )}`}
              />
            </FormField>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-3 form-group">
          <div className="form-group">
            <Label displayLabel={CURRENT_TOTAL_RWA_FORECAST} htmlFor="inpuState " required={false} />
            <input
              className="form-control"
              disabled={true}
              placeholder={"€123.00"}
              value={`€${decimalFormatter(currentTotalRwaForecast, 2)}`}
            />
          </div>
        </div>
        <div className="col-md-3 form-group">
          <div className="form-group">
            <Label displayLabel={RWA_ADJUSTMENT} htmlFor="inpuState " required={false} />
            <input
              type="number"
              className="form-control"
              placeholder={"Please enter the RWA amount"}
              onChange={e =>
                setAdjustmentCreateState({
                  ...adjustmentState,
                  rwa: {
                    isValid: !isEmpty(e.target.value),
                    value: !isEmpty(e.target.value) ? parseFloat(e.target.value) : null,
                  },
                  newTotalRwaForecast: {
                    isValid: getNewTotalRwaForecast(currentTotalRwaForecast,  (!isEmpty(e.target.value) ? parseFloat(e.target.value) : null)) >= 0,
                    value: getNewTotalRwaForecast(currentTotalRwaForecast,  (!isEmpty(e.target.value) ? parseFloat(e.target.value) : null)),
                  },
                  rwaQ4: {
                    isValid: true,
                    value: !isEmpty(e.target.value)
                      ? (((currentTotalRwaForecast ?? 0) + parseFloat(e.target.value))*4 - (rwaQ1 + rwaQ2 + rwaQ3))
                      : rwaQ4 ,
                  },
                  comment: {
                    isValid: isCommentValid(adjustmentState?.comment?.value, adjustmentState?.nbi?.value, (!isEmpty(e.target.value) ? parseFloat(e.target.value) : null)),
                    value: adjustmentState?.comment?.value ?? ""
                  },
                })
              }
              value={adjustmentState?.rwa?.value ?? ""}
            />
          </div>
        </div>
        <div className="col-md-3 form-group">
          <div className="form-group">
            <Label displayLabel={NEW_TOTAL_RWA_FORECAST} htmlFor="inpuState " required={false} />
            <input
              className="form-control"
              disabled={true}
              placeholder={"€123.00"}
              value={`€${decimalFormatter(
                adjustmentState?.newTotalRwaForecast?.value ?? (currentTotalRwaForecast ?? 0),
                2
              )}`}
            />
            <> { adjustmentState?.newTotalRwaForecast?.value < 0 && <span className="text-danger">Amount should not be negative.</span> } </>
          </div>
        </div>
      </div>
       <div className="row">
        <div className="col-md-3 form-group">
          <div className="form-group">
            <Label displayLabel={RWA_Q1} htmlFor="inpuState " required={false} />
            <input
              className="form-control"
              disabled={true}
              placeholder={"€123.00"}
              value={`€${decimalFormatter(rwaQ1, 2)}`}
            />
          </div>
        </div>
        <div className="col-md-3 form-group">
          <div className="form-group">
            <Label displayLabel={RWA_Q2} htmlFor="inpuState " required={false} />
            <input
              className="form-control"
              disabled={true}
              placeholder={"RWA Q1 amount"}
              value={`€${decimalFormatter(
                rwaQ2,
                2
              )}`}
            />
          </div>
        </div>
        <div className="col-md-3 form-group">
          <div className="form-group">
            <Label displayLabel={RWA_Q3} htmlFor="inpuState " required={false} />
            <input
              className="form-control"
              disabled={true}
              placeholder={"€123.00"}
              value={`€${decimalFormatter(
                rwaQ3,
                2
              )}`}
            />
          </div>
        </div>
        <div className="col-md-3 form-group">
          <div className="form-group">
            <Label displayLabel={RWA_Q4} htmlFor="inpuState " required={false} />
            <input
              className="form-control"
              disabled={true}
              placeholder={"€123.00"}
              value={`€${decimalFormatter(
                adjustmentState.rwaQ4?.value ?? rwaQ4 ?? 0,
                2
              )}`}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 form-group">
          <div className="form-group">
            <Label displayLabel={COMMENT} htmlFor="inpuState " required={adjustmentState?.rwa?.value >= 1000000 || adjustmentState?.nbi?.value >= 1000000} />
            <textarea
              className="form-control"
              placeholder={"Please enter a comment"}
              rows={4}
              maxLength={500}
              onChange={e =>
                setAdjustmentCreateState({
                  ...adjustmentState,
                  comment: {
                    isValid: isCommentValid(e.target.value, adjustmentState?.nbi?.value, adjustmentState?.rwa?.value),
                    value: e.target.value,
                  },
                })
              }
              value={adjustmentState?.comment?.value ?? ""}
            />
          </div>
        </div>
      </div>
      <div className="row mb-2">
        <div className="col-md-12 form-group">
          <FormField id={JUSTIFICATION} label={JUSTIFICATION} labelClassName="text-dark">
            <div className="row">
              <div className="col-md-12">
                <FileUploader
                  isValid={true}
                  onFileSelectSuccess={file => onSelectFileChange(file)}
                  onFileRemove={() => onFileRemove()}
                  fileAlreadyExist={
                    adjustmentState.justificationFile != null ? adjustmentState.justificationFileName : null
                  }
                  placeholder="Drag file"
                />
              </div>
            </div>
          </FormField>
        </div>
      </div>
    </Modal>
  );
};
