export const FORECAST_YEAR = "Forecast year";
export const FORECAST_PERIOD = "Forecast period";
export const CLIENT = "Client (business provider)";
export const SSBL = "Sub sub business line";
export const ORIGINATING_COUNTRY = "Originating country";
export const ORIGINATING_ENTITY = "Originating entity";
export const PRODUCT_LEVEL_3 = "Product level 3";
export const ERRROR_MESSAGE_A = "Please select a";
export const ISIB_LABEL = "Is Investment banking (IB) activity";
export const BL = "Business line";
export const SBL = "Sub business line";
export const DECNAME = "DEC name";
export const PRODUCT_LEVEL_1 = "Product level 1";
export const PRODUCT_LEVEL_2 = "Product level 2";
export const PRODUCT_LEVEL_4 = "Product level 4";
export const PRIMARY_OBLIGOR = "Primary obligor";
export const FORECAST_PERIOD_LIST = [
  {
    id: "FY",
    label: "FY",
  },
];
export const ISIB_ALL = "all";
