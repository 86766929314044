import { isEmpty, trim } from "lodash";
import queryString = require("query-string");

export const stringifyParams = params => {
  return !isEmpty(params) ? `?${queryString.stringify(params)}` : "";
};

export const doesContainSubString = (value: string, other: string, trimSpaces = true, ignoreCase = true): boolean => {
  const { value1, value2 } = trimSpaces
    ? { value1: trim(value), value2: trim(other) }
    : { value1: value, value2: other };
  return ignoreCase ? value1.toLowerCase().includes(value2.toLowerCase()) : value1.includes(value2);
};

export const computeUndefiniteArticle = (first: string, second: string): string =>
  ["a", "e", "i", "o", "u", "y"].some(c => second.toLocaleLowerCase().startsWith(c))
    ? `${first}n ${second}`
    : `${first} ${second}`;

export const replaceInvalidCharacters = (valueString: string): string => valueString.replace(/,/gi, "~,");
