import { AdjustmentCreateDto, ForecastCreationRequestDto } from "components/entities.typing";
import { get, isEmpty } from "lodash";
import { ToastMessage } from "./api.typings";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getErrorMessage = async (response: Response, toastMessage?: ToastMessage) => {
  const toast = get(toastMessage, "friendlyErrorMessage");
  if (toast) {
    return toast;
  }

  const responseObject = JSON.parse(await response.text());
  return !isEmpty(responseObject.detail)
    ? responseObject.detail
    : !isEmpty(response.statusText)
    ? response.statusText
    : responseObject.title;
};

export const getForecastFormData = (forecastCreationForm: ForecastCreationRequestDto): FormData => {
  const formData = new FormData();
  Object.keys(forecastCreationForm).forEach(key => {
    formData.append(key, forecastCreationForm[key]);
  });

  return formData;
};

export const getAdjustmentFormData = (adjustment: AdjustmentCreateDto): FormData => {
  const formData = new FormData();
  Object.keys(adjustment).forEach(key => {
    formData.append(key, adjustment[key]);
  });
  return formData;
};

export const DownloadFile = (response: Response): void => {
  if (!response.ok) {
    return;
  }
  const content = response.headers.get("Content-Disposition");
  const formatedFileName = formatFileName(content);
  response.blob().then(blob => {
    const downloadUrl = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = downloadUrl;
    a.download = formatedFileName;
    a.click();
  });
};

export const formatFileName = (content: string): string =>
  content
    .split(";")[1]
    .trim()
    .split("=")[1]
    .replace(/"/g, "");
