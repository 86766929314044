import * as React from "react";
import { MainLayout } from "components/layout/MainLayout/MainLayout";
import { BrowserRouter } from "react-router-dom";
import { AppRoutes } from "components/App/AppRoutes";
import { ErrorHandler } from "components/common/ErrorHandler/ErrorHandler";
import { MatomoProvider } from "@datapunt/matomo-tracker-react";
import { trackerInstance } from "utils/logger/tracker";
import { WebComponents } from "components/WebComponent/WebComponents";
import { Authentication } from "components/Authentication/Authentication";
import Compose from "components/common/Compose/Compose";
import { AuthenticationContextProvider } from "hooks/contexts/AuthenticationContext";

/**
 * This is your React application.
 * We use the react strict mode (https://reactjs.org/docs/strict-mode.html)
 * A global error handler (https://reactjs.org/blog/2017/07/26/error-handling-in-react-16.html)
 */
export const App: React.FC = () => {
  return (
    <>
      <WebComponents />
      <ErrorHandler>
        <React.StrictMode>
          <Compose components={[AuthenticationContextProvider]}>
            <MatomoProvider value={trackerInstance}>
              <BrowserRouter>
                <MainLayout>
                  <Authentication>
                    <AppRoutes />
                  </Authentication>
                </MainLayout>
              </BrowserRouter>
            </MatomoProvider>
          </Compose>
        </React.StrictMode>
      </ErrorHandler>
    </>
  );
};
