import * as React from "react";
import { Modal } from "components/common/Modal/Modal";
import { Label } from "components/common/ReadOnlyField/Label";
import {
  AdjustementCreateModel,
  ForecastCreateModel,
  JustificationModel,
} from "components/forecastCreate/forecastCreate.typing";
import {
  COMMENT,
  CURRENT_TOTAL_NBI_FORECAST,
  CURRENT_TOTAL_RWA_FORECAST,
  JUSTIFICATION,
  NBI_ADJUSTMENT,
  NEW_TOTAL_NBI_FORECAST,
  NEW_TOTAL_RWA_FORECAST,
  RWA_ADJUSTMENT,
} from "./adjustment.modal.const";
import { FileUploader } from "components/common/FileUploader/FileUploader";
import { isCommentValid, isFormValid } from "./adjustment.modal.helper";
import { FormField } from "components/common/ReadOnlyField/FormField";
import { isEmpty } from "lodash";
import { decimalFormatter } from "common/helpers";

const InitilizeModalData = (adjustement: AdjustementCreateModel) => {
  adjustement.nbi = { isValid: false, value: null };
  adjustement.currentTotalNbiForecast = { isValid: true, value: 0 };
  adjustement.newTotalNbiForecast = adjustement.currentTotalNbiForecast;
  adjustement.rwa = { isValid: false, value: null };
  adjustement.currentTotalRwaForecast = { isValid: true, value: 0 };
  adjustement.newTotalRwaForecast = adjustement.currentTotalRwaForecast;
  adjustement.comment = { isValid : true, value : "" };
};
export interface Props {
  show: boolean;
  onClose: () => void;
  onSubmit: () => void;
  forecastCreateModel: ForecastCreateModel;
}

export const AdjustmentModal: React.FC<Props> = ({ show, onClose, onSubmit, forecastCreateModel }: Props) => {
  InitilizeModalData(forecastCreateModel.Adjustment);
  const [isModalValid, setIsModalValid] = React.useState(false);
  const [adjustmentCreateState, setadjustmentCreateState] = React.useState<AdjustementCreateModel>(
    forecastCreateModel.Adjustment
  );
  const [justificationCreateState, setJustificationCreateState] = React.useState<JustificationModel>(
    forecastCreateModel.Justification
  );

  React.useEffect(() => {
    setIsModalValid(isFormValid(adjustmentCreateState));
  }, [adjustmentCreateState]);

  const handleCloseModal = () => {
    onClose();
  };

  const handleOnSumbit = () => {
    if (isFormValid(adjustmentCreateState)) {
      forecastCreateModel.Adjustment = {
        ...adjustmentCreateState,
        nbi: {
          isValid: adjustmentCreateState.nbi?.isValid,
          value: adjustmentCreateState.nbi?.value ? adjustmentCreateState.nbi?.value : 0,
        },
        rwa: {
          isValid: adjustmentCreateState.rwa?.isValid,
          value: adjustmentCreateState.rwa?.value ? adjustmentCreateState.rwa?.value : 0,
        },
      };
      forecastCreateModel.Justification = justificationCreateState;
      onSubmit();
    }
  };
  const onSelectFileChange = (file: File) => {
    setJustificationCreateState({
      ...forecastCreateModel.Justification,
      justificationFile: {
        isValid: true,
        value: file,
      },
    });
  };

  const onFileRemove = () => {
    setJustificationCreateState({
      ...justificationCreateState,
      justificationFile: {
        isValid: false,
        value: null,
      },
    });
  };

  return (
    <Modal
      size="xl"
      title="New Adjustment"
      cancelLabel="Cancel"
      submitLabel="Confirm"
      show={show}
      onClose={handleCloseModal}
      onCancel={handleCloseModal}
      onSubmit={handleOnSumbit}
      isSubmitValid={isModalValid}
    >
      <div className="row">
        <div className="col-md-3 form-group">
          <div className="form-group">
            <Label displayLabel={CURRENT_TOTAL_NBI_FORECAST} htmlFor="inpuState " required={false} />
            <input
              className="form-control"
              disabled={true}
              placeholder={"€123.00"}
              value={`€${decimalFormatter(
                parseFloat(adjustmentCreateState.currentTotalNbiForecast.value.toString()),
                2
              )}`}
            />
          </div>
        </div>
        <div className="col-md-3 form-group">
          <div className="form-group">
            <Label displayLabel={NBI_ADJUSTMENT} htmlFor="inpuState " required={false} />
            <input
              type="number"
              className="form-control"
              placeholder={"Please enter the NBI amount"}
              onChange={e =>
                setadjustmentCreateState({
                  ...adjustmentCreateState,
                  nbi: {
                    isValid: !isEmpty(e.target.value),
                    value: !isEmpty(e.target.value) ? parseFloat(e.target.value) : 0,
                  },
                  newTotalNbiForecast: {
                    isValid: true,
                    value: !isEmpty(e.target.value)
                      ? (adjustmentCreateState.currentTotalNbiForecast.value ?? 0) + parseFloat(e.target.value)
                      : adjustmentCreateState.currentTotalNbiForecast.value,
                  },
                  comment : {
                    isValid: isCommentValid(adjustmentCreateState?.comment?.value, !isEmpty(e.target.value) ? parseFloat(e.target.value) : 0, adjustmentCreateState?.rwa?.value),
                    value: adjustmentCreateState?.comment?.value
                  }
                })
              }
              value={adjustmentCreateState.nbi.value}
            />
          </div>
        </div>
        <div className="col-md-3 form-group">
          <div className="form-group">
            <Label displayLabel={NEW_TOTAL_NBI_FORECAST} htmlFor="inpuState " required={false} />
            <input
              className="form-control"
              disabled={true}
              placeholder={"€123.00"}
              value={`€${decimalFormatter(parseFloat(adjustmentCreateState.newTotalNbiForecast.value.toString()), 2)}`}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-3 form-group">
          <div className="form-group">
            <Label displayLabel={CURRENT_TOTAL_RWA_FORECAST} htmlFor="inpuState " required={false} />
            <input
              className="form-control"
              disabled={true}
              placeholder={"€123.00"}
              value={`€${decimalFormatter(
                parseFloat(adjustmentCreateState.currentTotalRwaForecast.value.toString()),
                2
              )}`}
            />
          </div>
        </div>
        <div className="col-md-3 form-group">
          <div className="form-group">
            <Label displayLabel={RWA_ADJUSTMENT} htmlFor="inpuState " required={false} />
            <input
              type="number"
              className="form-control"
              placeholder={"Please enter the RWA amount"}
              onChange={e =>
                setadjustmentCreateState({
                  ...adjustmentCreateState,
                  rwa: {
                    isValid: !isEmpty(e.target.value),
                    value: !isEmpty(e.target.value) ? parseFloat(e.target.value) : 0,
                  },
                  newTotalRwaForecast: {
                    isValid: true,
                    value: !isEmpty(e.target.value)
                      ? (adjustmentCreateState.currentTotalRwaForecast.value ?? 0) + parseFloat(e.target.value)
                      : adjustmentCreateState.currentTotalRwaForecast.value,
                  },
                  comment : {
                    isValid: isCommentValid(adjustmentCreateState?.comment?.value, adjustmentCreateState?.nbi?.value, !isEmpty(e.target.value) ? parseFloat(e.target.value) : 0),
                    value: adjustmentCreateState?.comment?.value
                  }
                })
              }
              value={adjustmentCreateState.rwa.value}
            />
          </div>
        </div>
        <div className="col-md-3 form-group">
          <div className="form-group">
            <Label displayLabel={NEW_TOTAL_RWA_FORECAST} htmlFor="inpuState " required={false} />
            <input
              className="form-control"
              disabled={true}
              placeholder={"€123.00"}
              value={`€${decimalFormatter(parseFloat(adjustmentCreateState.newTotalRwaForecast.value.toString()), 2)}`}
            />
          </div>
        </div>
      </div>
      <div className="row mb-2">
        <div className="col-md-12 form-group">
          <div className="form-group">
            <Label displayLabel={COMMENT} htmlFor="inpuState " required={adjustmentCreateState?.rwa?.value >= 1000000 || adjustmentCreateState?.nbi?.value >= 1000000} />
            <textarea
              className="form-control"
              placeholder={"Please enter a comment"}
              rows={4}
              maxLength={500}
              onChange={e =>
                setadjustmentCreateState({
                  ...adjustmentCreateState,
                  comment: {
                    isValid: isCommentValid(e.target.value, adjustmentCreateState?.rwa?.value, adjustmentCreateState?.nbi?.value),
                    value: e.target.value,
                  },
                })
              }
              value={adjustmentCreateState?.comment?.value ?? ""}
            />
          </div>
        </div>
      </div>
      <div className="row mb-2">
        <div className="col-md-12 form-group">
          <FormField id={JUSTIFICATION} label={JUSTIFICATION} labelClassName="text-dark">
            <div className="row">
              <div className="col-md-12">
                <FileUploader
                  isValid={true}
                  onFileSelectSuccess={file => onSelectFileChange(file)}
                  onFileRemove={() => onFileRemove()}
                  placeholder="Drag file"
                />
              </div>
            </div>
          </FormField>
        </div>
      </div>
    </Modal>
  );
};
