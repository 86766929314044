import * as React from "react";
import { CalculatedForecastBody } from "./CalculatedForecastBody";
import { ProjectionsDetailsDto } from "../entities.typings";
import { CalculatedForecastHeader, ColumnsProps } from "./CalculatedForecastHeader";

export interface ICalculatedForecastProps {
  projectionDetails: ProjectionsDetailsDto[];
}
const columnsProps: ColumnsProps[] = [
  {
    label: "Supplier",
    isAmount: false,
  },
  {
    label: "NBI",
    isAmount: true,
  },
  {
    label: "RWA",
    isAmount: true,
  },
  {
    label: "Details",
    isAmount: false,
  },
  {
    label: "Corresponding deals",
    isAmount: false,
  },
];
export const CalculatedForecast: React.FC<ICalculatedForecastProps> = ({
  projectionDetails = [],
}: ICalculatedForecastProps) => {
  return (
    <div>
      <h4>Computed forecast</h4>
      <table className="table table-hover table-sm" width="30px">
        <CalculatedForecastHeader columns={columnsProps} />
        <CalculatedForecastBody projectionDetails={projectionDetails} />
      </table>
    </div>
  );
};
