import { DropDown } from "components/common/DropDown/DropDown";
import AccountSinglePicker from "components/common/Pickers/SingleAccountPicker/AccountSinglePicker";
import { Label } from "components/common/ReadOnlyField/Label";
import { Referential } from "components/entities.typing";
import * as React from "react";
import createLogger from "utils/logger/logger.helper";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { Actions } from "./actions";
import ReferentialPicker from "../ReferentialPicker/ReferentialPicker";
import {
  CLIENT,
  FORECAST_PERIOD,
  FORECAST_PERIOD_LIST,
  FORECAST_YEAR,
  ISIB_ALL,
  ISIB_LABEL,
  ORIGINATING_COUNTRY,
  ORIGINATING_ENTITY,
  PRODUCT_LEVEL_3,
  SSBL,
} from "common/forecast.const";
import { SearchFiltersModel } from "./searchFilters.typings";
import { forecastFeatures } from "utils/logger/features/forecast.features";
import { isValidFormState } from "../forecastSearch.helper";

const logger = createLogger();

interface Props {
  onSearchClick: (searchFilters: SearchFiltersModel) => void;
  exportMyAdjustmentsClick: (year: string) => void;
  years: Referential[];
  onForecastYearChange: (value: string) => void;
}

export const INIT_STATE: SearchFiltersModel = {
  ForecastYear: { isValid: true, value: new Date().getFullYear().toString() },
  BusinessProvider: { dropdownState: { isValid: true, selected: null }, clientId: undefined },
  Ssbl: { isValid: true, selected: null },
  OriginatingCountry: { isValid: true, selected: null },
  OriginatingEntity: { isValid: true, selected: null },
  ProductLevel: { isValid: true, selected: null },
  IsIb: { isValid: true, value: ISIB_ALL },
};

export const SearchFilters: React.FC<Props> = ({
  onSearchClick,
  exportMyAdjustmentsClick,
  years,
  onForecastYearChange,
}: Props) => {
  const [forecastSearchFiltersState, setForecastSearchFiltersState] = React.useState<SearchFiltersModel>(INIT_STATE);
  const [isFilterStateValid, setIsFilterStateValid] = React.useState<boolean>(false);
  const { trackEvent } = useMatomo();

  React.useEffect(() => {
    setIsFilterStateValid(isValidFormState(forecastSearchFiltersState));
  }, [forecastSearchFiltersState]);

  React.useEffect(() => {
    setForecastSearchFiltersState({
      ...forecastSearchFiltersState,
      Ssbl: {
        ...forecastSearchFiltersState.Ssbl,
        isValid: isValidFormState(forecastSearchFiltersState),
      },
    });
  }, [forecastSearchFiltersState.Ssbl.isValid]);
  const handleYearChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    onForecastYearChange(event.target.value);
  };

  const resetForm = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setForecastSearchFiltersState(INIT_STATE);
    trackEvent(forecastFeatures.forecastResetForm);
    logger.functional("Forecasts search screen", "Reset button clicked", "cpr-forecast-ui");
  };
  const handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();    
    trackEvent(forecastFeatures.forecastSubmitSearch);
    onSearchClick(forecastSearchFiltersState);
  };
  const exportMyAdjustments = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    trackEvent(forecastFeatures.exportMyAdjustments);
    exportMyAdjustmentsClick(forecastSearchFiltersState.ForecastYear.value);
  };
  const onIsIbChange = (e: React.ChangeEvent<HTMLInputElement>) => {    
    setForecastSearchFiltersState({
      ...forecastSearchFiltersState,
      IsIb: {
        value: e.target.value,
        isValid: true,
      },
    });
  };

  return (
    <>
      <div className="row">
        <div className="ml-2 mt-3">
          <h3>Search forecasts</h3>
        </div>
      </div>
      <div className="row">
        <div className="col-md-1 form-group">
          <div className="form-group">
            <Label displayLabel={FORECAST_YEAR} htmlFor="inpuState " />
            <DropDown
              selectedValue={forecastSearchFiltersState.ForecastYear.value}
              defaultValue={new Date().getFullYear().toString()}
              emptyItem={false}
              className={"text-center"}
              options={years}
              required={false}
              disabled={false}
              onChange={e => {
                setForecastSearchFiltersState({
                  ...forecastSearchFiltersState,
                  ForecastYear: {
                    value: e.target.value,
                    isValid: true,
                  },
                });
                handleYearChange(e);
              }}
            />
          </div>
        </div>
        <div className="col-md-1 form-group">
          <div className="form-group">
            <Label displayLabel={FORECAST_PERIOD} htmlFor="inpuState " />
            <DropDown className={"text-center"} options={FORECAST_PERIOD_LIST} required={false} disabled={true} />
          </div>
        </div>
        <div className="col-md-3 form-group">
          <Label displayLabel={CLIENT} htmlFor="inpuState " />
          <AccountSinglePicker
            selectedId={forecastSearchFiltersState.BusinessProvider?.clientId ?? undefined}
            isInError={!forecastSearchFiltersState.BusinessProvider?.dropdownState?.isValid}
            errorMessage={"Please select a Client"}
            onChange={e => {
              setForecastSearchFiltersState({
                ...forecastSearchFiltersState,
                BusinessProvider: {
                  dropdownState: {
                    selected: { id: e.bdrCommercialId, label: e.name },
                    isValid: true,
                  },
                  clientId: e.account?.id,
                },
              });
            }}
          />
        </div>
        <div className="col-md-3 form-group">
          <Label displayLabel={ISIB_LABEL} htmlFor="inpuState " />
          <div className="form-group">
            <input
              type="radio"
              name="IsIb"
              value={ISIB_ALL}
              checked={forecastSearchFiltersState.IsIb.value === ISIB_ALL}
              onChange={onIsIbChange}
            />{" "}
            All &nbsp;
            <input
              type="radio"
              value="false"
              name="IsIb"
              checked={forecastSearchFiltersState.IsIb.value === "false"}
              onChange={onIsIbChange}
            />{" "}
            Non IB &nbsp;
            <input
              type="radio"
              value="true"
              name="IsIb"
              checked={forecastSearchFiltersState.IsIb.value === "true"}
              onChange={onIsIbChange}
            />{" "}
            IB only
          </div>
        </div>
      </div>
      <div className="row">
        <div className="form-group col-md-3">
          <ReferentialPicker
            label={SSBL}
            disabled={false}
            isValid={true}
            selected={forecastSearchFiltersState.Ssbl?.selected}
            onSelect={selected => {
              setForecastSearchFiltersState({
                ...forecastSearchFiltersState,
                Ssbl: {
                  isValid: true,
                  selected: selected,
                },
              });
            }}
          />
        </div>
        <div className="form-group col-md-3">
          <ReferentialPicker
            label={ORIGINATING_COUNTRY}
            disabled={false}
            isValid={true}
            selected={forecastSearchFiltersState.OriginatingCountry?.selected}
            onSelect={selected => {
              setForecastSearchFiltersState({
                ...forecastSearchFiltersState,
                OriginatingCountry: {
                  isValid: true,
                  selected: selected,
                },
              });
            }}
            includeKeys={false}
          />
        </div>
        <div className="form-group col-md-3">
          <div className="form-group">
            <ReferentialPicker
              label={ORIGINATING_ENTITY}
              disabled={false}
              isValid={true}
              selected={forecastSearchFiltersState.OriginatingEntity?.selected}
              onSelect={selected => {
                setForecastSearchFiltersState({
                  ...forecastSearchFiltersState,
                  OriginatingEntity: {
                    isValid: true,
                    selected: selected,
                  },
                  OriginatingCountry: {
                    isValid: true,
                    selected: null,
                  },
                });
              }}
              includeKeys={true}
            />
          </div>
        </div>
        <div className="form-group col-md-3">
          <div className="form-group">
            <ReferentialPicker
              label={PRODUCT_LEVEL_3}
              disabled={false}
              isValid={true}
              selected={forecastSearchFiltersState.ProductLevel?.selected}
              onSelect={selected => {
                setForecastSearchFiltersState({
                  ...forecastSearchFiltersState,
                  ProductLevel: {
                    isValid: true,
                    selected: selected,
                  },
                });
              }}
              includeKeys={true}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="form-group col-md-3 mb-0">
          <div className="form-group mb-0">
            <label>
              <i>Note : fill all input fields to speed-up the search</i>
            </label>
          </div>
        </div>
        <Actions
          handleSubmit={handleSubmit}
          resetForm={resetForm}
          exportMyAdjustments={exportMyAdjustments}
          searchReady={isFilterStateValid}
          exportMyAdjustmentsReady={forecastSearchFiltersState.ForecastYear.isValid}
        />
      </div>
    </>
  );
};
