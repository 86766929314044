import { AdjustementCreateModel } from "components/forecastCreate/forecastCreate.typing";
import { isEmpty } from "lodash";

export const isFormValid = (adjustement: AdjustementCreateModel): boolean => {
  return (adjustement.nbi.isValid || adjustement.rwa.isValid) && (adjustement?.comment?.isValid ?? true);
};

export const isCommentValid = (comment: string, nbiAdjustment: number, rwaAdjustment: number) => {
  return (!isEmpty(comment) || !(nbiAdjustment >= 1000000 || rwaAdjustment >= 1000000)) && (comment?.length ?? 0) < 500;
};
