import { CampaignApi } from "api/campaigns.api";
import { ForecastApi } from "api/forecasts.api";
import { Loading } from "components/common/Loading/Loading";
import { CampaignDto, Forecast } from "components/entities.typing";
import { calculateStatusAndLastAdjustmentBy } from "components/forecastCreate/forecast.mapper";
import ForecastsList from "components/forecastsList/foreCastList/forecastsList";
import { get, some } from "lodash";
import * as React from "react";
import { SearchFilters } from "../Filters/searchFilters";
import { SearchFiltersModel } from "../Filters/searchFilters.typings";

const ForecastSearchContainer: React.FC = () => {
  const [forecastsList, setForecastsList] = React.useState<Forecast[]>([]);
  const [isFetching, setIsFetching] = React.useState<boolean>(false);
  const [isNewForecastButtonDisabled, setIsNewForecastDisabled] = React.useState<boolean>(true);
  const [campaigns, setCampaigns] = React.useState<CampaignDto[]>([]);
  const [forecastYearSearch, setForecastYearSearch] = React.useState("");
  const [searchFiltersModel, setSearchFiltersModel] = React.useState(null);

  function onSearchClick(filters: SearchFiltersModel) {
    fetchForecasts(filters);
  }

  function exportMyAdjustmentsClick(year: string) {
    exportMyAdjustments(year);
  }

  React.useEffect(() => {
    (async () => {
      const campaigns = await CampaignApi.getCampaings();
      setCampaigns(get(campaigns, "campaigns").sort((a, b) => (a.year > b.year ? 1 : -1)));
    })();
  }, []);

  const fetchForecasts = (filters: SearchFiltersModel) => {
    setIsFetching(true);
    setSearchFiltersModel(filters);
    setForecastYearSearch(filters.ForecastYear.value);
    ForecastApi.getForecasts(filters)
      .then(result => {        
        setForecastsList(calculateStatusAndLastAdjustmentBy(result.forecasts));
        setIsFetching(false);
        if (result.forecasts.length === 0) {
          const disableNewForecast = some(
            campaigns,
            campaign => campaign.year.toString() === filters.ForecastYear.value && campaign.status !== "Opened"
          );
          setIsNewForecastDisabled(disableNewForecast);
        } else {
          setIsNewForecastDisabled(true);
        }
      })
      .catch(() => setIsFetching(false));
  };

  const exportMyAdjustments = (year: string) => {
    setIsFetching(true);
    ForecastApi.exportMyAdjustments(year)
      .then(() => {
        setIsFetching(false);
      })
      .catch(() => setIsFetching(false));
  };

  const handleNewForecastButton = (selectedYear: string) => {
    if (forecastYearSearch !== selectedYear) {
      setIsNewForecastDisabled(true);
    } else {
      const disableNewForecast = some(
        campaigns,
        campaign => campaign.year.toString() === selectedYear && campaign.status !== "Opened"
      );
      setIsNewForecastDisabled(disableNewForecast);
    }
  };

  const getYearsFromCampaigns = campaigns
    ? campaigns.map(element => {
        return { id: element.year.toString(), label: element.year.toString() };
      })
    : [];

  return (
    <div className="container-fluid">
      <Loading isVisible={isFetching} />
      <SearchFilters
        onSearchClick={onSearchClick}
        exportMyAdjustmentsClick={exportMyAdjustmentsClick}
        onForecastYearChange={handleNewForecastButton}
        years={getYearsFromCampaigns}
      />
      <ForecastsList
        forecastsList={forecastsList}
        isNewForecastDisabled={isNewForecastButtonDisabled}
        searchFiltersModel={searchFiltersModel}
      />
    </div>
  );
};

export default ForecastSearchContainer;
