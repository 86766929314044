import { Forecast, ForecastCreationRequestDto } from "components/entities.typing";
import { ForecastCreateModel } from "./forecastCreate.typing";

export const mapRequestToForecastCreateForm = (request: ForecastCreateModel): ForecastCreationRequestDto => {
  return {
    forecastYear: parseInt(request.ForecastYear.value),
    businessProvider: request.BusinessProvider.selected.label,
    businessProviderId: request.BusinessProvider.selected.id,
    primaryObligor: request.PrimaryObligor.selected.label,
    primaryObligorId: request.PrimaryObligor.selected.id,
    bl: request.Bl.selected.label,
    ssbl: request.Ssbl.selected.label,
    isIb: Boolean(JSON.parse(request.IsIb.value)),
    originatingEntity: request.OriginatingEntity.selected.label,
    originatingEntityId: request.OriginatingEntity.selected.id,
    originatingCountry: request.OriginatingCountry.selected.label,
    decName:
      request.DecName.selected != null && request.DecName.selected.label !== undefined
        ? request.DecName.selected.label
        : null,
    decNameId:
      request.DecName.selected != null && request.DecName.selected.id !== undefined
        ? request.DecName.selected.id
        : null,
    productLevel1: request.ProductLevel1.selected.label,
    productLevel2: request.ProductLevel2.selected.label,
    productLevel3: request.ProductLevel3.selected.label,
    productLevel4: request.ProductLevel4.selected.label,
    productLevelId: request.ProductLevel3.selected.id,
    nbiAdjustment: request.Adjustment.nbi.isValid ? request.Adjustment.nbi.value : 0,
    rwaAdjustment: request.Adjustment.rwa.isValid ? request.Adjustment.rwa.value : 0,
    comment: request.Adjustment.comment.isValid ? request.Adjustment.comment.value : "",
    justificationFile: request.Justification.justificationFile.isValid
      ? request.Justification.justificationFile.value
      : null,
  };
};

export const calculateStatusAndLastAdjustmentBy = (forecasts: Forecast[]): Forecast[] => {
  forecasts.forEach(forecast => {
    forecast.status = forecast.adjustments !== undefined && forecast.adjustments.length > 0 ? "Adjusted" : null;
    forecast.lastAdjustementBy = forecast.adjustments !== undefined && forecast.adjustments.length > 0 ? forecast.adjustments?.sort((a, b) => (a.creationDate > b.creationDate ? 1 : -1))[
      forecast.adjustments?.length - 1
    ].createdBy: "";
  });
  return forecasts;
}