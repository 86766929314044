import * as React from "react";
import { Button } from "components/common/Buttons/Button";

export interface ActionsProps {
  handleSubmit: (e: React.MouseEvent<HTMLButtonElement>) => void;
  resetForm: (e: React.MouseEvent<HTMLButtonElement>) => void;
  exportMyAdjustments: (e: React.MouseEvent<HTMLButtonElement>) => void;
  searchReady: boolean;
  exportMyAdjustmentsReady: boolean;
}

export const Actions: React.FC<ActionsProps> = ({
  handleSubmit,
  resetForm,
  exportMyAdjustments,
  searchReady,
  exportMyAdjustmentsReady,
}: ActionsProps) => {
  return (
    <div className="col-sm">
      <div className="form-group form-group">
        <div className="btn-group float-right" role="group">
          <Button
            className="ml-2 btn-default btn-icon-text"
            onClick={exportMyAdjustments}
            disabled={!exportMyAdjustmentsReady}
          >
            <em className="icon icon-sm">file_download</em>Export my adjustements
          </Button>
          <Button className="ml-2 btn-default btn-icon-text" onClick={resetForm}>
            <em className="icon icon-sm">refresh</em>Reset
          </Button>
          <Button
            type="submit"
            className="ml-2 btn btn-primary btn-icon-text"
            onClick={handleSubmit}
            disabled={!searchReady}
            tooltip="Please fill at least two criteria before searching"
          >
            <em className="icon icon-sm">search</em>
            Search
          </Button>
        </div>
      </div>
    </div>
  );
};
