import * as React from "react";
import { Adjustment } from "components/entities.typing";
import { AdjustmentListRow } from "./AdjustmentListRow";
import { map, sumBy } from "lodash";
import { TotalAdjustmentRow } from "./TotalAdjustmentRow";

export interface IAdjustmentListBodyProps {
  adjustments: Adjustment[];
  campaignStatus: string;
  onDeleteAdjustment: (id: number) => void;
  onEditAdjustment: (id: number) => void;
}
export const AdjustmentListBody: React.FC<IAdjustmentListBodyProps> = ({
  adjustments,
  campaignStatus,
  onDeleteAdjustment,
  onEditAdjustment,
}: IAdjustmentListBodyProps) => {
  return (
    <tbody>
      {map(adjustments, adjustment => (
        <AdjustmentListRow
          adjustment={adjustment}
          campaignStatus={campaignStatus}
          onDeleteAdjustment={onDeleteAdjustment}
          onEditAdjustment={onEditAdjustment}
        />
      ))}
      {
        <TotalAdjustmentRow
          totalLabel={"Total adjustments"}
          totalNbi={sumBy(adjustments, "nbiAdjustment")}
          totalRwa={sumBy(adjustments, "rwaAdjustment")}
        />
      }
    </tbody>
  );
};
