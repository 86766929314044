import * as React from "react";
import { BackButton } from "@dcc-cli/ic-shared-components";

export interface Props {
  message: string;
  header?: string;
}

export const ErrorMessage: React.FC<Props> = ({ header, message }: Props) => {
  return message ? (
    <div className="w-50 mx-auto mt-5">
      <h1 className="display-3 text-danger">{header || "Something went wrong"}</h1>
      {message && <p className="lead">{`Error detail: ${message}`}</p>}
      <div className="ml-1">
        <BackButton />
      </div>
    </div>
  ) : null;
};
