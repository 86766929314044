import * as React from "react";
import { map, sumBy } from "lodash";
import { ProjectionsDetailsDto } from "../entities.typings";
import { CalculatedForecastRow } from "./CalculatedForecastRow";
import { CalculatedForecastTotal } from "./CalculatedForecastTotal";
export interface ICalculatedForecastBodyProps {
  projectionDetails: ProjectionsDetailsDto[];
}

export const CalculatedForecastBody: React.FunctionComponent<ICalculatedForecastBodyProps> = ({
  projectionDetails,
}: ICalculatedForecastBodyProps) => {
  return (
    <tbody>
      {map(projectionDetails, projectionDetail => (
        <CalculatedForecastRow projectionDetail={projectionDetail} key={projectionDetail.id} />
      ))}
      {
        <CalculatedForecastTotal
          totalLabel="Total computed forecast"
          totalNbi={sumBy(projectionDetails, "nbiForecast")}
          totalRwa={sumBy(projectionDetails, "rwaForecast")}
        />
      }
    </tbody>
  );
};
