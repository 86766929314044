import {
  BL,
  CLIENT,
  DECNAME,
  ORIGINATING_COUNTRY,
  ORIGINATING_ENTITY,
  PRIMARY_OBLIGOR,
  PRODUCT_LEVEL_1,
  PRODUCT_LEVEL_2,
  PRODUCT_LEVEL_3,
  PRODUCT_LEVEL_4,
  SBL,
  SSBL,
} from "common/forecast.const";

export const REFERENTIALS = {
  [BL]: "fpv_bl",
  [SBL]: "fpv_sbl",
  [SSBL]: "fpv_ssbl",
  [ORIGINATING_ENTITY]: "fpv_oeshortlabel",
  [ORIGINATING_COUNTRY]: "fpv_oeresctryname",
  [DECNAME]: "agency_name_and_rescode",
  [PRODUCT_LEVEL_1]: "product_l1",
  [PRODUCT_LEVEL_2]: "product_l2",
  [PRODUCT_LEVEL_3]: "product_l3",
  [PRODUCT_LEVEL_4]: "product_l4",
  [PRIMARY_OBLIGOR]: "po_lefullname_and_id",
  [CLIENT]: "client_lename_and_id",
};
