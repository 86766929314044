import * as React from "react";

export interface Props {
  columns: string[];
  numericColumnsIndex?: number[];
}

export const TableHeader: React.FC<Props> = ({ columns, numericColumnsIndex }: Props) => {
  return (
    <thead>
      <tr>
        {columns.map((element, key) => (
          <th key={key} scope="col" className={numericColumnsIndex?.includes(key) ? `${"text-right"}` : ""}>
            <h5>{element}</h5>
          </th>
        ))}
      </tr>
    </thead>
  );
};
