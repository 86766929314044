import * as React from "react";
import { CalculatedForecastHeader, ColumnsProps } from "../CalculatedForecastSection/CalculatedForecastHeader";
import { ForecastDetailsTotalRow } from "./ForecastDetailsTotalRow";

export interface IForecastDetailsTotalProps {
  totalNbi: number;
  totalRwa: number;
}

export const ForecastDetailsTotal: React.FC<IForecastDetailsTotalProps> = ({
  totalNbi,
  totalRwa,
}: IForecastDetailsTotalProps) => {
  const columnsProps: ColumnsProps[] = [
    {
      label: "",
      isAmount: false,
    },
    {
      label: "NBI",
      isAmount: true,
    },
    {
      label: "RWA",
      isAmount: true,
    },
  ];
  return (
    <div className="mt-5">
      <h4>Total (Computed forecast + Adjustments)</h4>
      <div>
        <table className="table table-hover table">
          <CalculatedForecastHeader columns={columnsProps} />
          <tbody>
            <ForecastDetailsTotalRow
              totalLabel="Total (Computed forecast + Adjustments)"
              totalNbi={totalNbi}
              totalRwa={totalRwa}
            />
          </tbody>
        </table>
      </div>
    </div>
  );
};
