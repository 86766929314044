import { Adjustment, AdjustmentCreateDto } from "components/entities.typing";
import { IAdjustmentApi } from "./adjustments.api.typing";
import { createRepository, getAuthenticationHeader } from "./api";
import { DownloadFile, getAdjustmentFormData } from "./api.helpers";
import { ToastMessage } from "./api.typings";

export const AdjustmentApi: IAdjustmentApi = {
  async createAdjustment(request: AdjustmentCreateDto): Promise<Adjustment> {
    const formData = getAdjustmentFormData(request);

    return createRepository().post<FormData, Adjustment>("/api/v1/adjustments", getAuthenticationHeader(), formData, {
      successMessage: "Adjustment created successfully",
    });
  },
  async downloadAdjustmentJustification(id: number): Promise<void> {
    await createRepository()
      .getStream(`/api/v1/adjustments/${id}/justification`, getAuthenticationHeader(), null, {
        friendlyErrorMessage: "The file download failed",
        ressourceNotFoundMessage: "Justification not found",
      } as ToastMessage)
      .then(response => {
        DownloadFile(response);
      });
  },
  async deleteAdjustment(id: number): Promise<void> {
    await createRepository().delete(`/api/v1/adjustments/${id}`, getAuthenticationHeader(), null, {
      successMessage: "Adjustment deleted successfully",
    });
  },
  getAdjustment: (forecastId: number): Promise<Adjustment> =>
    createRepository().get<Adjustment>(`/api/v1/adjustments/${forecastId}`, getAuthenticationHeader()),

  async updateAdjustment(adjustment: AdjustmentCreateDto): Promise<Adjustment> {
    const formData = getAdjustmentFormData(adjustment);

    return createRepository().put<FormData, Adjustment>(
      `/api/v1/adjustments/${adjustment.id}`,
      getAuthenticationHeader(),
      formData,
      { successMessage: "Adjustment updated successfully" }
    );
  },
};
