import * as React from "react";
import { sgwtConnect } from "common/auth/sgwtConnect";
import { authenticationReducer, AuthenticationActions } from "./reducers/authentication.reducer";
import { WidgetConfiguration } from "@sg-widgets/shared-core";
import { globalConfiguration } from "common/config/config";

export interface AuthenticationState {
  loggedIn: boolean;
  connectedUser: string;
}

const initialState: AuthenticationState = {
  loggedIn: false,
  connectedUser: undefined,
};

export const getWidgetConfiguration = (): WidgetConfiguration => (window as any).SGWTWidgetConfiguration;

export const useAuthentication = () => {
  const [authState, authDispatch] = React.useReducer(authenticationReducer, initialState);

  React.useEffect(() => {
    if (sgwtConnect.isAuthorized) {
      publishAuthToken(sgwtConnect.getAuthorizationHeader());

      sgwtConnect.on('renewAuthorizationSuccess', () => {
        publishAuthToken(sgwtConnect.getAuthorizationHeader());
      });

      authDispatch({ type: AuthenticationActions.Authenticate, user: sgwtConnect.getIdTokenClaims().sub });
    }

    return () => {
      sgwtConnect.discardAuthorization();
    };
  }, [sgwtConnect.isAuthorized]);

  return { authState };
};

function publishAuthToken(token: string): void {
  const widgetConfiguration = getWidgetConfiguration();
  widgetConfiguration.bus.publish("sg-connect.access-token", token);
  widgetConfiguration.bus.publish("sg-connect.granted-scope", globalConfiguration.sgConnectConfig.scopes);
}
