import * as React from "react";
import { Label } from "components/common/ReadOnlyField/Label";
import SelectDropDownSearch from "components/common/SelectDropDownSearch/SelectDropDownSearch";
import referentialApi from "api/referentials.api";
import { debounce, get, noop } from "lodash";
import { computeUndefiniteArticle } from "utils/strings/stringUtils";
import { buildLikeFilter } from "../forecastSearch.helper";
import { Referential } from "components/entities.typing";
import { ERRROR_MESSAGE_A } from "common/forecast.const";
import { REFERENTIALS } from "common/referentials.const";

export interface ReferentialPickerProps {
  label: ReferentialLabel;
  disabled: boolean;
  isValid: boolean;
  selected: Referential;
  referentials?: Referential[];
  displayLabel?: boolean;
  labelClassName?: string;
  onSelect: (selected: Referential) => void;
  includeKeys?: boolean;
  isRequired?: boolean;
  filteredAttribute?: string;
  valueAttribute?: string;  
  filerAttributeToComplete?: string;
  valueAttributeToComplete?: string;
}

export type ReferentialLabel = keyof typeof REFERENTIALS;

const ReferentialPicker: React.FC<ReferentialPickerProps> = ({
  label,
  disabled,
  selected,
  isValid,
  onSelect,
  referentials,
  displayLabel = true,
  labelClassName = "text-secondary mb-0",
  includeKeys = false,
  isRequired = false,
  filteredAttribute = null,
  valueAttribute = null,
  filerAttributeToComplete = null,
  valueAttributeToComplete = null
}: ReferentialPickerProps) => {
  const [items, setItems] = React.useState<Referential[]>(referentials ?? []);

  let referentialValues: Referential[];

  const handleOnSearch = async (term?: string) => {
    if (!includeKeys) {
      const searchedItems = await referentialApi.getReferentialsValues(
        REFERENTIALS[label],
        buildLikeFilter(REFERENTIALS[label], term, filteredAttribute, valueAttribute, filerAttributeToComplete, valueAttributeToComplete)
      );
      referentialValues = get(searchedItems, "values").map(referentialValue => {
        return { id: referentialValue, label: referentialValue };
      });
    } else {
      const searchedItems = await referentialApi.getReferentialsKeyValues(
        REFERENTIALS[label],
        buildLikeFilter(REFERENTIALS[label], term, filteredAttribute, valueAttribute, filerAttributeToComplete, valueAttributeToComplete)
      );
      referentialValues = get(searchedItems, "keysValues").map(referentialValue => {
        return { id: referentialValue.key, label: referentialValue.value };
      });
    }
    setItems(referentialValues);
  };

  return (
    <>
      <div className="form-group">
        {displayLabel && (
          <Label displayLabel={label} htmlFor="inpuState " className={labelClassName} required={isRequired} />
        )}
        <SelectDropDownSearch
          items={items}
          isValid={isValid}
          invalidMessage={computeUndefiniteArticle(ERRROR_MESSAGE_A, label)}
          onSelection={onSelect}
          selected={selected}
          onChangeSearchTerm={!referentials ? debounce(e => handleOnSearch(e), 500) : noop}
          disabled={disabled}
        />
      </div>
    </>
  );
};

export default ReferentialPicker;
