import { IReferentialApi, ReferentialsKeyValues, ReferentialsValues } from "./referentials.api.typings";
import { createRepository, getAuthenticationHeader } from "./api";

const REFERENTIAL_PATH = `/api/v1/referentials/attributes/`;

const referentialApi: IReferentialApi = {
  getReferentialsValues: (attributeId: string, filter: string): Promise<ReferentialsValues> =>
    createRepository().get<ReferentialsValues>(
      `${REFERENTIAL_PATH}${attributeId}/values?filter=${filter}`,
      getAuthenticationHeader()
    ),

  getReferentialsKeyValues: (attributeId: string, filter: string): Promise<ReferentialsKeyValues> =>
    createRepository().get<ReferentialsKeyValues>(
      `${REFERENTIAL_PATH}${attributeId}/keyvalues?filter=${filter}`,
      getAuthenticationHeader()
    ),
};

export default referentialApi;
