import { ForecastCreateModel } from "./forecastCreate.typing";

/**
 * Build a like filter
 * @param attributeId : attribute to filter on
 * @param term : term to search on attribute values
 */
export const buildLikeFilter = (attributeId: string, term: string): string => `${attributeId}:like:*${term}*`;

export const isValidFormState = (forecastCreateState: ForecastCreateModel): boolean => {
  return (
    forecastCreateState.ForecastYear.value != null &&
    forecastCreateState.BusinessProvider.selected != null &&
    forecastCreateState.PrimaryObligor.selected != null &&
    forecastCreateState.Bl.selected != null &&
    forecastCreateState.Sbl.selected != null &&
    forecastCreateState.Ssbl.selected != null &&
    forecastCreateState.OriginatingCountry.selected != null &&
    forecastCreateState.OriginatingEntity.selected != null &&
    forecastCreateState.IsIb.value != null &&
    forecastCreateState.ProductLevel1.selected != null &&
    forecastCreateState.ProductLevel2.selected != null &&
    forecastCreateState.ProductLevel3.selected != null &&
    forecastCreateState.ProductLevel4.selected != null
  );
};
