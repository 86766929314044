import { Environment } from "./config/config.typings";
import { globalConfiguration } from "./config/config";

export const getEnvironment = (): Environment => {
  const executionMode = process.env.NODE_ENV;
  if (executionMode !== "production") {
    return executionMode as Environment;
  }  
  return globalConfiguration.env;
};
