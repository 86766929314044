import * as React from "react";

export interface ColumnsProps {
  label: string;
  isAmount: boolean;
}
export interface ICalculatedForecastHeader {
  columns: ColumnsProps[];
}
export const CalculatedForecastHeader: React.FC<ICalculatedForecastHeader> = ({
  columns,
}: ICalculatedForecastHeader) => {
  return (
    <thead>
      <tr>
        {columns.map((element, key) => (
          <th key={key} scope="col" style={element.isAmount ? { textAlign: "right" } : {}}>
            {element.label}
          </th>
        ))}
      </tr>
    </thead>
  );
};
