/* eslint-disable react/prop-types */
import { ProjectionsDetailsDto } from "../entities.typings";
import * as React from "react";
import { Button } from "components/common/Buttons/Button";
import { noop } from "lodash";
import { getDetailsText } from "./calculatedForecast.utils";
import { Icon } from "components/common/Icon/Icon";
import { decimalFormatter } from "common/helpers";

export interface ICalculatedForecastRowProps {
  projectionDetail: ProjectionsDetailsDto;
}

export const CalculatedForecastRow: React.FunctionComponent<ICalculatedForecastRowProps> = ({
  projectionDetail: { nbi, nbiForecast, rwa, rwaForecast, supplierId, forecastParameter, rwaQ1, rwaQ2, rwaQ3, rwaQ4 },
}) => (
  <tr key={forecastParameter?.id}>
    <td>
      <b>{forecastParameter?.supplier ?? supplierId}</b>
    </td>
    <td style={{ textAlign: "right" }}>{`€${decimalFormatter(nbiForecast, 2)}`}</td>
    <td style={{ textAlign: "right" }}>{`€${decimalFormatter(rwaForecast, 2)}`}</td>
    <td
      dangerouslySetInnerHTML={{
        __html: getDetailsText(forecastParameter ? nbi : rwa, forecastParameter, rwaQ1, rwaQ2, rwaQ3, rwaQ4),
      }}
    ></td>
    <td>
      <Button type="button" className="btn btn-secondary btn-icon-text" onClick={noop} disabled={true}>
        <Icon iconName="pageview" size="md" />
        Open
      </Button>
    </td>
  </tr>
);
