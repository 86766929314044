import { decimalFormatter } from "common/helpers";
import * as React from "react";

export interface IBoldAmountProps {
  amount: number;
}

export const BoldAmount: React.FC<IBoldAmountProps> = ({ amount }: IBoldAmountProps) => {
  return <b>{`€${decimalFormatter(amount, 2)}`}</b>;
};
