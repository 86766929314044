import { Switch } from "@sgbs-ui/core";
import { AdjustmentApi } from "api/adjustments.api";
import { ForecastApi } from "api/forecasts.api";
import { ROUTES } from "common/routes";
import { Icon } from "components/common/Icon/Icon";
import { Adjustment, AdjustmentCreateDto, Forecast } from "components/entities.typing";
import ForecastSearchContainer from "components/searchFilters/Container/forecastSearchContainer";
import { get, map, sum, sumBy } from "lodash";
import * as React from "react";
import { Redirect, RouteComponentProps } from "react-router";
import { Route } from "react-router-dom";
import { AdjustmentModal } from "./AdjustementModal/AdjustementModal";
import { getRwaQ4 } from "./AdjustementModal/AdjustmentModal.helper";
import { AdjustmetList } from "./AdjustmentListSection/AdjustmetList";
import { CalculatedForecast } from "./CalculatedForecastSection/CalculatedForecast";
import { ForecastSearchCriteria } from "./ForecastSearchSection/ForecastSearchCriteria";
import { ForecastDetailsTotal } from "./TotalSection/ForecastDetailsTotal";

const FORECAST = "FORECAST";
type OwnProps = RouteComponentProps<{ id: string }>;
export const ForecastDetailContainer: React.FC<OwnProps> = ({ match }: OwnProps) => {
  const [projection, setProjection] = React.useState<Forecast>(null);
  const [adjustment, setAdjustment] = React.useState<Adjustment>(null);
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const [found, setFound] = React.useState<boolean>(true);
  const [isForecastCreatedFromScratch, setIsForecastCreatedFromScratch] = React.useState<boolean>(false);
  React.useEffect(() => {
    getProjection();
  }, [match.params.id]);

  const getProjection = () => {
    (async () => {
      ForecastApi.getForecastById(match.params.id).then(result => {
        if (result instanceof Response && !(result as Response).ok) {
          setFound(false);
        } else {
          setProjection(result as Forecast);
          setIsForecastCreatedFromScratch(
            (result as Forecast).projectionDetails.some(x => x.supplierId === FORECAST && x.sourceId === FORECAST)
          );
        }
      });
    })();
  };

  const onNewAdjustmentClick = () => {
    setShowModal(true);
  };

  const onAdjustmentSubmit = (adjustmentDto: AdjustmentCreateDto, creationMode: boolean) => {
    if (creationMode) {
      adjustmentDto.aggregatedProjectionId = projection.id;
      AdjustmentApi.createAdjustment(adjustmentDto).then(() => fetchProjection(projection.id.toString()));
    } else {
      AdjustmentApi.updateAdjustment(adjustmentDto).then(() => fetchProjection(projection.id.toString()));
    }
    setShowModal(false);
    setAdjustment(null);
  };

  const fetchProjection = async (projectionId: string): Promise<void> => {
    await ForecastApi.getForecastById(projectionId).then(result => {
      setProjection(result as Forecast);
    });
  };

  const onModalClose = () => {
    setAdjustment(null);
    setShowModal(false);
  };

  const onEditAdjustment = async (adjustmentId: number) => {
    await AdjustmentApi.getAdjustment(adjustmentId)
      .then(result => {
        setAdjustment(result);
      })
      .then(() => {
        setShowModal(true);
      });
  };

  const totalNbi = sum([
    sumBy(projection?.adjustments, "nbiAdjustment"),
    sumBy(projection?.projectionDetails, "nbiForecast"),
  ]);
  const totalNbiComputedForecast = sumBy(projection?.projectionDetails, "nbiForecast");

  const totalRwa = sum([
    sumBy(projection?.adjustments, "rwaAdjustment"),
    sumBy(projection?.projectionDetails, "rwaForecast"),
  ]);
  const totalRwaComputedForecast = sumBy(projection?.projectionDetails, "rwaForecast");
  
  const totalRwaQ1 = sumBy(projection?.projectionDetails, "rwaQ1");
  const totalRwaQ2 = sumBy(projection?.projectionDetails, "rwaQ2");
  const totalRwaQ3 = sumBy(projection?.projectionDetails, "rwaQ3");
  
  const totalRwaQ4 = getRwaQ4(totalRwaComputedForecast, totalRwaQ1,totalRwaQ2, totalRwaQ3);
  return (
    <>
      {!found && (
        <Switch>
          <Route path={ROUTES.SEARCH} component={ForecastSearchContainer} exact={true} />
          <Redirect to={ROUTES.SEARCH}></Redirect>
        </Switch>
      )}
      <a href="search" className="text-large line-height-1 btn-link d-inline-block pb-3 mt-3">
        <Icon iconName="arrow_back" className="pb-1" size="md" />
        Go back to main search
      </a>
      <div>
        <AdjustmentModal
          showModal={showModal}
          onClose={onModalClose}
          onSubmit={onAdjustmentSubmit}
          adjustment={adjustment}
          currentTotalNbiForecast={totalNbiComputedForecast}
          currentTotalRwaForecast={totalRwaComputedForecast}
          rwaQ1={totalRwaQ1}
          rwaQ2={totalRwaQ2}
          rwaQ3={totalRwaQ3}
          rwaQ4={totalRwaQ4}
        ></AdjustmentModal>
        <ForecastSearchCriteria projection={projection} />
        {!isForecastCreatedFromScratch && (
          <CalculatedForecast projectionDetails={get(projection, "projectionDetails")} />
        )}
        <AdjustmetList
          adjustments={map(get(projection, "adjustments"))}
          campaignStatus={projection?.campaign?.status}
          refetchProjection={getProjection}
          onClick={onNewAdjustmentClick}
          onEditAdjustment={onEditAdjustment}
        />
        <ForecastDetailsTotal totalNbi={totalNbi} totalRwa={totalRwa} />
      </div>
    </>
  );
};
