import { startsWith } from "lodash";

const CONTACT_API_URL = "https://api-s.sgmarkets.com/thirdparties/contacts/v1";
const BI_API_UAT_URL = "https://cpr-bi-api-uat.fr.world.socgen";
const ERROR_MESSAGE = "Cannot get current environment from configuration";

export const getApiServiceUrl = (url: string, usingMock = false): string => {
  if (usingMock) {
    return "/";
  }
  const isRootUrl = startsWith(url, "http://") || startsWith(url, "https://");

  if (isRootUrl) {
    return "";
  }

  const isContactsApiCall = url.includes("/sg/contacts");
  if (isContactsApiCall) {
    return getIcServiceForExtendedContactUrl();
  }

  if (url.includes("/2.0/attributes")) {
    return getBiApiAttributesUrl();
  }

  return getForecastApiUrl();
};

export const getIcServiceForExtendedContactUrl = (): string => {
  switch (window["globalConfiguration"].env) {
    case "local":
      return getEnvironment() === "production"
        ? "https://contacts-api.fr.world.socgen/1.0"
        : "https://contacts-api-hom.fr.world.socgen/1.0";
    case "DEV":
      return "https://contacts-api-hom.fr.world.socgen/1.0";
    case "UAT":
      return CONTACT_API_URL;
    case "ISO":
      return CONTACT_API_URL;
    case "PROD":
      return CONTACT_API_URL;
    default:
      throw Error(ERROR_MESSAGE);
  }
};

export const getForecastApiUrl = (): string => {
  switch (window["globalConfiguration"].env) {
    case "local":
      return "https://cprofit-forecast-v2-api-dev.fr.world.socgen";
    case "DEV":
      return "https://cprofit-forecast-v2-api-dev.fr.world.socgen";
    case "UAT":
      return "https://cprofit-forecast-v2-api-uat.fr.world.socgen";
    case "ISO":
      return "https://cprofit-forecast-v2-api-iso.fr.world.socgen";
    case "PROD":
      return "https://cprofit-forecast-v2-api.fr.world.socgen";
    default:
      throw Error(ERROR_MESSAGE);
  }
};

export const getBiApiAttributesUrl = (): string => {
  switch (window["globalConfiguration"].env) {
    case "local":
      return BI_API_UAT_URL;
    case "DEV":
      return BI_API_UAT_URL;
    case "UAT":
      return BI_API_UAT_URL;
    case "ISO":
      return "https://cpr-bi-api-iso.fr.world.socgen";
    case "PROD":
      return "https://cpr-bi-api.fr.world.socgen";
    default:
      throw Error(ERROR_MESSAGE);
  }
};

export const getEnvironment = (): string =>
  window["globalConfiguration"].sgConnectConfig.widgetConfiguration.environment;
