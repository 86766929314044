export const CURRENT_TOTAL_NBI_FORECAST = "Current total NBI forecast (€)";
export const NBI_ADJUSTMENT = "NBI Adjustment (€)";
export const NEW_TOTAL_NBI_FORECAST = "New total NBI forecast (€)";
export const CURRENT_TOTAL_RWA_FORECAST = "Current total RWA forecast AVG (€)";
export const RWA_ADJUSTMENT = "RWA adjustment (€)";
export const NEW_TOTAL_RWA_FORECAST = "New total RWA forecast (€) AVG";
export const COMMENT = "Comment";
export const JUSTIFICATION = "Justification";
export const RWA_Q1 = "RWA Q1";
export const RWA_Q2 = "RWA Q2";
export const RWA_Q3 = "RWA Q3";
export const RWA_Q4 = "RWA Q4";
