/**
 * centralize all the routes of your application
 */
export const SGMARKETS_URL = "https://cprofit.sgmarkets.com/#/";
export const ROUTES = {
  HOME: "/",
  SEARCH: "/search",
  PROJECTION_DETAIL: "/projections/:id/details",
  NOT_FOUND: "/404",
  CREATE: "/create",
};
