import { ReadOnlyField } from "components/common/ReadOnlyField/ReadOnlyField";
import { Forecast } from "components/entities.typing";
import { first } from "lodash";
import * as React from "react";

interface IForecastSearchCriteriaProps {
  projection: Forecast;
}
export const ForecastSearchCriteria: React.FC<IForecastSearchCriteriaProps> = ({
  projection,
}: IForecastSearchCriteriaProps) => (
  <>
    <h4>Forecast criteria</h4>
    <div className="row my-4">
      <div className="col">
        <div className="row">
          <ReadOnlyField
            id={"forecast_year"}
            label={"Forecast year"}
            value={`${projection?.campaign?.year}`}
            className="col-2"
          />
          <ReadOnlyField id={"forecast_period"} label={"Forecast period"} value={"FY"} className="col-2" />
          <ReadOnlyField
            id={"client"}
            label={"Client (business provider)"}
            value={projection?.businessProvider}
            className="col-2"
          />
          <ReadOnlyField
            id={"primay_obligor"}
            label={"Primary obligor"}
            value={projection?.primaryObligor}
            className="col-2"
          />
          <ReadOnlyField id={"bl"} label={"Business line"} value={projection?.bl} className="col-2" />
          <ReadOnlyField
            id={"sbl"}
            label={"Sub business line"}
            value={first(projection?.projectionDetails)?.forecastParameter?.sbl}
            className="col-2"
          />
        </div>
        <div className="row">
          <ReadOnlyField
            id={"originating_entity"}
            label={"Originating entity"}
            value={projection?.originatingEntity}
            className="col-2"
          />
          <ReadOnlyField
            id={"originating_country"}
            label={"Originating country"}
            value={projection?.originatingCountry}
            className="col-2"
          />
          <ReadOnlyField id={"dec_name"} label={"Dec name"} value={projection?.decName} className="col-2" />
          <ReadOnlyField id={"ssbl"} label={"Sub sub business line"} value={projection?.ssbl} className="col-2" />
          <ReadOnlyField
            id={"is_ib"}
            label="Is Investment banking (IB) activity"
            value={projection?.isIb === "1" ? "Yes" : "No"}
            className="col-2"
          />
        </div>
        <div className="row">
          <ReadOnlyField
            id={"product_l1"}
            label={"Product level 1"}
            value={projection?.productLevel1}
            className="col-2"
          />
          <ReadOnlyField
            id={"product_l2"}
            label={"Product level 2"}
            value={projection?.productLevel2}
            className="col-2"
          />
          <ReadOnlyField
            id={"product_l3"}
            label={"Product level 3"}
            value={projection?.productLevel3}
            className="col-2"
          />
          <ReadOnlyField
            id={"product_l4"}
            label={"Product level 4"}
            value={projection?.productLevel4}
            className="col-2"
          />
        </div>
      </div>
    </div>
    <hr />
  </>
);
