import * as React from "react";
import { map } from "lodash";
import { getWidgetConfiguration } from "hooks/useAuthentication";

export const WebComponents: React.FunctionComponent = () => {
  const scripts = ["ic-widgets/v1/ic-account-single-picker.js"];

  const getBaseUrl = (): string => {
    const widgetConfiguration = getWidgetConfiguration();
    if (widgetConfiguration?.environment !== "production") {
      return "https://shared-uat.sgmarkets.com/";
    }
    return "https://shared.sgmarkets.com/";
  };
  React.useEffect(() => {
    map(scripts, source => {
      const script = document.createElement("script");
      script.src = `${getBaseUrl()}${source}`;
      document.body.appendChild(script);
    });
  }, []);
  return null;
};
