import * as React from "react";
import * as ReactDOM from "react-dom";
import { App } from "components/App/App";
// entry point for applying SG Boostrap 4 style
import "styles/main.scss";
import { sgwtConnect } from "common/auth/sgwtConnect";

/**
 * entry point of the application
 * we check the current user authentication:
 * if yes: we render the application
 * if no: we try to connect the current user
 */
if (sgwtConnect.isAuthorized()) {
  ReactDOM.render(<App />, document.getElementById("root"));
} else if (!sgwtConnect.getAuthorizationError()) {
  sgwtConnect.requestAuthorization();
}
