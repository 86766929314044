import { AdjustmentApi } from "api/adjustments.api";
import { Button } from "components/common/Buttons/Button";
import { Loading } from "components/common/Loading/Loading";
import { DeleteConfirmationModal } from "components/common/Modal/DeleteConfirmationModal";
import { Adjustment } from "components/entities.typing";
import * as React from "react";
import { CalculatedForecastHeader, ColumnsProps } from "../CalculatedForecastSection/CalculatedForecastHeader";
import { AdjustmentListBody } from "./AdjustmentListBody";

const OPENED_STATUS = "Opened";

export interface IAdjustmetListProps {
  campaignStatus: string;
  adjustments: Adjustment[];
  onClick: () => void;
  refetchProjection?: () => void;
  onEditAdjustment: (id: number) => void;
}

export const AdjustmetList: React.FC<IAdjustmetListProps> = ({
  adjustments,
  campaignStatus,
  onClick,
  refetchProjection,
  onEditAdjustment,
}: IAdjustmetListProps) => {
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [adjustementIdToDelete, setAdjustementIdToDelete] = React.useState<number>(null);

  const onDeleteAdjustment = (id: number) => {
    setAdjustementIdToDelete(id);
    setShowDeleteModal(true);
  };
  const onCloseModal = () => {
    setShowDeleteModal(false);
  };
  const onDeleteModal = () => {
    setIsLoading(true);
    AdjustmentApi.deleteAdjustment(adjustementIdToDelete)
      .then(() => {
        refetchProjection();
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
    setShowDeleteModal(false);
  };
  const columnsProps: ColumnsProps[] = [
    {
      label: "Id",
      isAmount: false,
    },
    {
      label: "Author",
      isAmount: false,
    },
    {
      label: "NBI",
      isAmount: true,
    },
    {
      label: "RWA",
      isAmount: true,
    },
    {
      label: "Last modified on",
      isAmount: false,
    },
    {
      label: "Last modified by",
      isAmount: false,
    },
    {
      label: "Justification",
      isAmount: false,
    },
    {
      label: "Actions",
      isAmount: false,
    },
    {
      label: "Comment",
      isAmount: false,
    },
  ];
  return (
    <div className="mt-5">
      <Loading isVisible={isLoading} />
      <DeleteConfirmationModal
        show={showDeleteModal}
        title="Adjustment deletion confirmation"
        onClose={onCloseModal}
        message="Are you sure you want to delete this adjustment?"
        onDelete={onDeleteModal}
      />
      <div className="d-flex mb-2">
        <h4>Adjustment(s)</h4>
        <Button
          className="ml-1 btn btn-md btn-primary float-right ml-4"
          onClick={onClick}
          disabled={campaignStatus !== OPENED_STATUS}
        >
          <i className="icon mr-1">add</i>New adjustment
        </Button>
      </div>
      <div>
        <table className="table table-hover table-sm" width="30px">
          <CalculatedForecastHeader columns={columnsProps} />
          <AdjustmentListBody
            adjustments={adjustments}
            campaignStatus={campaignStatus}
            onDeleteAdjustment={onDeleteAdjustment}
            onEditAdjustment={onEditAdjustment}
          />
        </table>
      </div>
    </div>
  );
};
