import { ISIB_ALL } from "common/forecast.const";
import { Forecast, ForecastCreationRequestDto, Forecasts } from "components/entities.typing";
import { SearchFiltersModel } from "components/searchFilters/Filters/searchFilters.typings";
import { createRepository, getAuthenticationHeader } from "./api";
import { getForecastFormData } from "./api.helpers";
import { DownloadFile } from "./api.helpers";
import { ToastMessage } from "./api.typings";
import { IForecastApi } from "./forecasts.api.typings";

export const ForecastApi: IForecastApi = {
  async getForecasts(filters: SearchFiltersModel): Promise<Forecasts> {
    const forecastYear = filters.ForecastYear.value ? `forecastYear=${filters.ForecastYear.value}` : "";
    const ssblfilter = filters.Ssbl.selected ? `&ssbl=${encodeURIComponent(filters.Ssbl.selected.label)}` : "";
    const bpFilter =
      filters.BusinessProvider.dropdownState.selected?.id !== undefined
        ? `&businessProviderId=${encodeURIComponent(filters.BusinessProvider.dropdownState.selected.id)}`
        : "";
    const originatingCountryFilter = filters.OriginatingCountry.selected
      ? `&originatingCountry=${encodeURIComponent(filters.OriginatingCountry.selected.label)}`
      : "";
    const originatingEntityFilter = filters.OriginatingEntity.selected
      ? `&originatingentity=${encodeURIComponent(filters.OriginatingEntity.selected.label)}`
      : "";
    const productLevel3Filter = filters.ProductLevel.selected
      ? `&ProductLevel3=${encodeURIComponent(filters.ProductLevel.selected.label)}`
      : "";
    const iSIbFilter =  filters.IsIb.value !== ISIB_ALL ? filters.IsIb.value === "true"? `&isib=true` : "&isib=false":"";

    return createRepository().get<Forecasts>(
      `/api/v1/forecast/search?${forecastYear}${ssblfilter}${bpFilter}${originatingCountryFilter}${originatingEntityFilter}${productLevel3Filter}${iSIbFilter}`,
      getAuthenticationHeader()
    );
  },
  async createForecast(request: ForecastCreationRequestDto): Promise<Forecast> {
    return createRepository().post<FormData, Forecast>(
      "/api/v1/projections",
      getAuthenticationHeader(),
      getForecastFormData(request),
      { successMessage: "Forecast created successfully" }
    );
  },
  async getForecastById(id: string): Promise<Forecast | Response> {
    return createRepository().get<Forecast>(`/api/v1/projections/${id}`, getAuthenticationHeader(), null, {
      friendlyErrorMessage: "Projection not found",
      ressourceNotFoundMessage: "Projection not found",
    } as ToastMessage);
  },
  async exportMyAdjustments(year: string): Promise<void> {
    await createRepository()
      .getStream(`/api/v1/forecast/export-my-adjustments?year=${year}`, getAuthenticationHeader(), null, {
        friendlyErrorMessage: "The file download failed",
        ressourceNotFoundMessage: "My adjustments not found",
      } as ToastMessage)
      .then(response => {
        DownloadFile(response);
      });
  },
};
