import { createLogger as createWebLogger, Environment } from "@dcc-cli/cli-web-logger";
import { getWidgetConfiguration } from "hooks/useAuthentication";

const widgetConfiguration = getWidgetConfiguration();

const mapToLogApiEnvironment = (environment: string): Environment => {
  switch (environment) {
    case "homologation":
      return "HOM";
    case "production":
      return "PRD";
    default:
      return "HOM";
  }
};

type Logger = ReturnType<typeof createWebLogger>;

const createLogger = (): Logger => {
  const env = widgetConfiguration?.environment;
  return createWebLogger("CPR", mapToLogApiEnvironment(env));
};

export default createLogger;
