import { ForecastParametersDto } from "../entities.typings";

export const getDetailsText = (amount: number, forecastParameter?: ForecastParametersDto, rwaQ1?: number, rwaQ2?: number, rwaQ3?: number, rwaQ4?: number): string => {
  if (!forecastParameter) {
    return `${amount?.toFixed(2) ?? "-"} RWA Actuals Q1: ${rwaQ1?.toFixed(2)}, Q2: ${rwaQ2?.toFixed(2)}, projection Q3: ${rwaQ3?.toFixed(2)}, Q4; ${rwaQ4?.toFixed(2)}`;
  }
  const startDate = new Date(forecastParameter.startDate);
  const endDate = new Date(forecastParameter.endDate);
  return `=€${amount.toFixed(2)} from ${formatDateText(startDate)} to ${formatDateText(endDate)}  <b>x${
    forecastParameter.multiplier
  }</b>`;
};

const formatDateText = (date: Date) =>
  `${date
    .getDate()
    .toString()
    .padStart(2, "0")}-${date.toLocaleDateString("en-us", {
    month: "short",
  })}-${date.getFullYear()}`;
