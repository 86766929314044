import * as React from "react";
import { NotFound } from "components/layout/NotFound/NotFound";
import { ROUTES } from "common/routes";
import { Redirect, Route, Switch } from "react-router";
import ForecastSearchContainer from "components/searchFilters/Container/forecastSearchContainer";
import ForecastCreateContainer from "components/forecastCreate/container/forecastCreateContainer";
import { ForecastDetailContainer } from "components/ForecastDetails/ForecastDetailContainer";

/**
 * contains all the bindings from routes to application components
 * https://reacttraining.com/react-router/web/api/Switch
 */
export const AppRoutes: React.FunctionComponent = () => (
  <Switch>
    <Redirect exact={true} from={ROUTES.HOME} to={ROUTES.SEARCH} />
    <Route path={ROUTES.SEARCH} component={ForecastSearchContainer} />
    <Route path={ROUTES.CREATE} component={ForecastCreateContainer} />
    <Route path={ROUTES.PROJECTION_DETAIL} component={ForecastDetailContainer} />
    <Route path={ROUTES.NOT_FOUND} component={NotFound} />
    <Route component={NotFound} />
  </Switch>
);
