import * as React from "react";
import { Modal } from "./Modal";

export interface Props {
  show: boolean;
  title: string;
  onClose: () => void;
  onDelete: () => void;
  message: string;
}
export const DeleteConfirmationModal: React.FC<Props> = ({ show, title, onClose, onDelete, message }: Props) => {
  return (
    <Modal
      size="xs"
      cancelLabel="Cancel"
      backdropClose={false}
      isLoading={false}
      title={title}
      show={show}
      submitLabel="Delete"
      isSubmitValid={true}
      onCancel={onClose}
      onClose={onClose}
      onSubmit={onDelete}
      btnSubmitClassName="btn btn-mars"
    >
      <div className="alert alert-outline-danger" role="alert">
        {message}
      </div>
    </Modal>
  );
};
