import { round } from "lodash";

export const parseValue = (value: number | string): number => (typeof value === "string" ? parseFloat(value) : value);

const countDecimals = (value: number): number => {
  if (Math.floor(value) === value) {
    return 0;
  }

  const stringValue = value.toString();
  if (stringValue.indexOf(".") !== -1) {
    return stringValue.split(".")[1].length || 0;
  }

  return 0;
};

export const divisionBy = (value: string | number, divisor: number): number => {
  const dividedValue = parseValue(value) / divisor;
  const roundingPrecision =
    countDecimals(dividedValue) > 10 && countDecimals(parseValue(value)) < 10 ? 10 : countDecimals(dividedValue);

  return round(parseValue(value) / divisor, roundingPrecision);
};
