import * as React from "react";
import { ErrorMessage } from "components/layout/ErrorMessage/ErrorMessage";

interface ErrorState {
  hasError: boolean;
  errorMessage?: string;
}

/**
 * Implementation of the error handling (https://reactjs.org/blog/2017/07/26/error-handling-in-react-16.html)
 */

// eslint-disable-next-line @typescript-eslint/ban-types
export class ErrorHandler extends React.Component<{}, ErrorState> {
  constructor(props) {
    super(props);
    this.state = { hasError: false, errorMessage: undefined };

    // Catches JS errors (including events), while componentDidCatch catches React errors
    // window.addEventListener("error", event => {
    //   if (event.error.hasBeenCaught !== undefined) {
    //     return false;
    //   }
    //   event.error.hasBeenCaught = true;
    //   // log in kibana
    // });
  }

  public render() {
    if (this.state.hasError) {
      return <ErrorMessage message={this.state.errorMessage} />;
    }
    return this.props.children;
  }

  public componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    this.setState({ hasError: true, errorMessage: error ? error.message : " " });
  }
}
