import * as React from "react";
import { BoldAmount } from "../Common/BoldAmount";

export interface ITotalRowProps {
  totalNbi: number;
  totalRwa: number;
  totalLabel: string;
}

export const TotalAdjustmentRow: React.FC<ITotalRowProps> = ({ totalNbi, totalRwa, totalLabel }: ITotalRowProps) => (
  <tr key="totalAdjustmentRow" className="table-success">
    <td>
      <b>{totalLabel}</b>
    </td>
    <td />
    <td style={{ textAlign: "right" }}>
      <BoldAmount amount={totalNbi} />
    </td>
    <td style={{ textAlign: "right" }}>
      <BoldAmount amount={totalRwa} />
    </td>
    <td />
    <td />
    <td />
    <td />
    <td />
  </tr>
);
