import { isInteger, round } from "lodash";
import { DateTime } from "luxon";
import { divisionBy } from "utils/math/round";

export const formatDateToPeriodDisplay = (date: string): string => {
  return DateTime.fromISO(date).toFormat("yyyy-MM");
};

// Removes the time from the date
export const formatDate = (date: string): string => {
  return date ? DateTime.fromISO(date).toFormat("d MMM yyyy") : "";
};

export const formatFileName = (content: string): string => {
  return content
    .split(";")[1]
    .trim()
    .split("=")[1]
    .replace(/"/g, "");
};

export const decimalFormatter = (value: number, roundingNumber?: number): string => {
  return round(divisionBy(value, 100) * 100, roundingNumber).toLocaleString("en-US", {
    maximumFractionDigits: roundingNumber ?? 0,
    minimumFractionDigits: roundingNumber ?? 0,
  });
};

export const formatNumber = (value: number): string => {
  return isInteger(value) ? value.toString() : decimalFormatter(value, 2);
};

export const formatPercentage = (value: number): string =>
  Number.isNaN(value) ? "NaN" : `${decimalFormatter(value * 100, 2)}%`;

export const percentageToNumber = (value: string): number => {
  if (value) {
    return value.endsWith("%") ? divisionBy(value.replace("%", ""), 100) : divisionBy(value, 100);
  }
  return NaN;
};
