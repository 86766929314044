import { DropDownState } from "common/common.typings";
import { SearchFiltersModel } from "./Filters/searchFilters.typings";

/**
 * Build a like filter
 * @param attributeId : attribute to filter on
 * @param term : term to search on attribute values
 */
export const buildLikeFilter = (
  attributeId: string,
  term: string,
  filteredAttribute?: string,
  valueAttribute?: string,
  filerAttributeToComplete?: string,
  valueAttributeToComplete?: string,
): string => { 
  if (filerAttributeToComplete === null)
  {
    return (filteredAttribute === null ? `${attributeId}:like:*${term}*` : `${filteredAttribute}:${valueAttribute}`);
  }
  else
  {
    return (filteredAttribute === null ? `${attributeId}:like:*${term}*,${filerAttributeToComplete}:${valueAttributeToComplete}` : `${filteredAttribute}:${valueAttribute},${filerAttributeToComplete}:${valueAttributeToComplete}`);
  }
  
}

export const isValidFormState = (forecastSearchFiltersState: SearchFiltersModel): boolean => {
  const filters = [
    forecastSearchFiltersState.BusinessProvider,
    forecastSearchFiltersState.OriginatingCountry,
    forecastSearchFiltersState.OriginatingEntity,
    forecastSearchFiltersState.ProductLevel,
    forecastSearchFiltersState.Ssbl,
    forecastSearchFiltersState.ForecastYear,
  ];

  const filtered = filters.filter(
    item =>
      (item as DropDownState).selected != null &&
      (item as DropDownState).selected.id !== undefined &&
      (item as DropDownState).selected?.label !== ""
  );
  return (
    (filtered.length >= 2 && forecastSearchFiltersState.ForecastYear.value != null) ||
    forecastSearchFiltersState.BusinessProvider.dropdownState.selected?.id !== undefined
  );
};
