import { AuthenticationState } from "hooks/useAuthentication";

export enum AuthenticationActions {
  Authenticate = "AUTHENTICATE",
  FailAuthentication = "FAIL_AUTHENTICATION",
  SetToken = "SET_TOKEN",
}

export type AuthenticationAction =
  | {
      type: AuthenticationActions.Authenticate;
      user: string;
    }
  | {
      type: AuthenticationActions.FailAuthentication;
    };

export const authenticationReducer = (
  state: AuthenticationState,
  action: AuthenticationAction
): AuthenticationState => {
  switch (action.type) {
    case AuthenticationActions.Authenticate:
      return { ...state, loggedIn: true, connectedUser: action.user };
    case AuthenticationActions.FailAuthentication:
      return { ...state, loggedIn: false, connectedUser: "" };
    default:
      throw new Error();
  }
};
