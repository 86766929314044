export const forecastFeatures = {
  forecastSubmitSearch: {
    category: "forecastSearch",
    action: "forecastSearch.button.search",
  },
  forecastResetForm: {
    category: "forecastSearch",
    action: "forecastSearch.button.reset",
  },
  exportMyAdjustments: {
    category: "forecastSearch",
    action: "forecastSearch.button.exportMyAdjustments",
  },
};
