import { ForecastCreateModel, ReferentialSearch } from "./forecastCreate.typing";

export const INIT_STATE: ForecastCreateModel = {
  ForecastYear: { isValid: true, value: new Date().getFullYear().toString() },
  BusinessProvider: { isValid: false, selected: null },
  PrimaryObligor: { isValid: false, selected: null },
  Bl: { isValid: false, selected: null },
  Sbl: { isValid: false, selected: null },
  Ssbl: { isValid: false, selected: null },
  OriginatingCountry: { isValid: false, selected: null },
  OriginatingEntity: { isValid: false, selected: null },
  DecName: { isValid: true, selected: null },
  ProductLevel1: { isValid: false, selected: null },
  ProductLevel2: { isValid: false, selected: null },
  ProductLevel3: { isValid: false, selected: null },
  ProductLevel4: { isValid: false, selected: null },
  IsIb: { isValid: false, value: null },
  Adjustment: {
    nbi: { isValid: false, value: null },
    rwa: { isValid: false, value: null },
    currentTotalNbiForecast: { isValid: true, value: 0.0 },
    newTotalNbiForecast: { isValid: true, value: 0.0 },
    currentTotalRwaForecast: { isValid: true, value: 0.0 },
    newTotalRwaForecast: { isValid: true, value: 0.0 },
  },
  Justification: { justificationFile: { isValid: false, value: null } },
};
export const FORECAST_PERIOD_LIST = [
  {
    id: "FY",
    label: "FY",
  },
];

export const updateInitState = (referentialSearch: ReferentialSearch): void => {
  INIT_STATE.ForecastYear =
    referentialSearch.forecastYear != null
      ? { isValid: true, value: referentialSearch.forecastYear }
      : INIT_STATE.ForecastYear;
  INIT_STATE.BusinessProvider =
    referentialSearch.businessProviders.length > 0
      ? { isValid: true, selected: referentialSearch.businessProviders[0] }
      : { isValid: false, selected: null };
  INIT_STATE.Bl =
    referentialSearch.businessLines.length > 0
      ? { isValid: true, selected: referentialSearch.businessLines[0] }
      : { isValid: false, selected: null };
  INIT_STATE.Sbl =
    referentialSearch.subBusinessLines.length > 0
      ? { isValid: true, selected: referentialSearch.subBusinessLines[0] }
      : { isValid: false, selected: null };
  INIT_STATE.Ssbl =
    referentialSearch.subSubBusinessLines.length > 0
      ? { isValid: true, selected: referentialSearch.subSubBusinessLines[0] }
      : { isValid: false, selected: null };
  INIT_STATE.OriginatingEntity =
    referentialSearch.originatingEntities.length > 0
      ? { isValid: true, selected: referentialSearch.originatingEntities[0] }
      : { isValid: false, selected: null };
  INIT_STATE.OriginatingCountry =
    referentialSearch.originatingCountries.length > 0
      ? { isValid: true, selected: referentialSearch.originatingCountries[0] }
      : { isValid: false, selected: null };
  INIT_STATE.IsIb = referentialSearch.isIb === null?  { isValid: false, value: null } :  referentialSearch.isIb === true? { isValid: true, value: "true" }:{ isValid: true, value: "false" };
  INIT_STATE.ProductLevel1 =
    referentialSearch.productsL1 != null
      ? { isValid: true, selected: referentialSearch.productsL1[0] }
      : { isValid: false, selected: null };
  INIT_STATE.ProductLevel2 =
    referentialSearch.productsL2 != null
      ? { isValid: true, selected: referentialSearch.productsL2[0] }
      : { isValid: false, selected: null };
  INIT_STATE.ProductLevel3 =
    referentialSearch.productsL3 != null
      ? { isValid: true, selected: referentialSearch.productsL3[0] }
      : { isValid: false, selected: null };
  INIT_STATE.ProductLevel4 =
    referentialSearch.productsL4 != null
      ? { isValid: true, selected: referentialSearch.productsL4[0] }
      : { isValid: false, selected: null };
};
