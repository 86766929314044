import * as React from "react";
import { join, noop } from "lodash";
import { ComponentSize } from "components/common/components.typings";

interface IconProps {
  className?: string;
  size?: ComponentSize;
  title?: string;
  onClick?: (e?: any) => void;
}

interface MDIconProps extends IconProps {
  iconName: string;
}

export const generateIconClassName = (size?: ComponentSize, className?: string): string =>
  join(["icon", size ? `icon-${size}` : "", className || ""], " ").trim();

export const Icon: React.FunctionComponent<MDIconProps> = ({
  iconName,
  size,
  className,
  title,
  onClick = noop,
}: MDIconProps) => (
  <i className={generateIconClassName(size, className)} title={title} onClick={onClick}>
    {iconName}
  </i>
);

export const IconEllipsisH = (props: IconProps) => <Icon iconName={"more_horiz"} {...props} />;
export const IconEye = (props: IconProps) => <Icon iconName={"remove_red_eye"} {...props} />;
export const IconInfo = (props: IconProps) => <Icon iconName={"info"} {...props} />;
export const IconFolder = (props: IconProps) => <Icon iconName={"folder_shared"} {...props} />;
export const IconExport = (props: IconProps) => <Icon iconName={"get_app"} {...props} />;
export const IconEdit = (props: IconProps) => <Icon iconName={"edit"} {...props} />;
export const IconWarning = ({ className = "text-danger", ...props }: IconProps) => (
  <Icon iconName={"warning"} className={className} {...props} />
);
export const IconCaretDown = (props: IconProps) => <Icon iconName={"expand_more"} {...props} />;
export const IconCaretRight = (props: IconProps) => <Icon iconName={"chevron_right"} {...props} />;
export const IconSpinner: React.FunctionComponent<IconProps> = ({ size = "md", className = "" }: IconProps) => (
  <svg
    className={`spinner spinner-${size} ${className}`}
    width="1.5rem"
    height="1.5rem"
    viewBox="0 0 66 66"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle className="spinner-circle" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30" />
  </svg>
);
export const IconCheckCircle = (props: IconProps) => <Icon iconName={"check_circle"} {...props} />;
export const IconBack = (props: IconProps) => <Icon iconName={"chevron_left"} {...props} />;
export const IconBackHome = (props: IconProps) => <Icon iconName={"arrow_back"} {...props} />;
export const IconClose = (props: IconProps) => <Icon iconName={"close"} {...props} />;
export const IconBusiness = (props: IconProps) => <Icon iconName={"business"} {...props} />;
export const IconSortAsc = (props: IconProps) => <Icon iconName={"arrow_upward"} {...props} />;
export const IconSortDesc = (props: IconProps) => <Icon iconName={"arrow_downward"} {...props} />;
export const IconSettings = (props: IconProps) => <Icon iconName={"settings"} {...props} />;
export const IconSearch = (props: IconProps) => <Icon iconName={"search"} {...props} />;
export const IconParameters = (props: IconProps) => <Icon iconName={"tune"} {...props} />;
export const IconExternalLink = (props: IconProps) => <Icon iconName={"launch"} {...props} />;
export const IconPaginationLeft = (props: IconProps) => <Icon iconName={"keyboard_arrow_left"} {...props} />;
export const IconPaginationRight = (props: IconProps) => <Icon iconName={"keyboard_arrow_right"} {...props} />;
export const IconDelete = (props: IconProps) => <Icon iconName={"delete"} {...props} />;
export const IconCreate = (props: IconProps) => <Icon iconName={"create"} {...props} />;
export const IconPerson = (props: IconProps) => <Icon iconName={"person"} {...props} />;
export const IconWork = (props: IconProps) => <Icon iconName={"work"} {...props} />;
export const IconCompareArrow = (props: IconProps) => <Icon iconName={"compare_arrows"} {...props} />;
export const IconAdd = (props: IconProps) => <Icon iconName={"add"} {...props} />;
export const IconRemove = (props: IconProps) => <Icon iconName={"remove"} {...props} />;
export const IconFilePresent = (props: IconProps) => <Icon iconName={"file_present"} {...props} />;
