import { Icon } from "components/common/Icon/Icon";
import * as React from "react";
import { Button } from "react-bootstrap";
export interface Props {
  display: boolean;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
}
const AdjustmentCreateAction: React.FC<Props> = ({ display, onClick }: Props) => {
  return (
    display && (
      <>
        <Button className="ml-1 mb-1 btn btn-md btn-primary float-right" onClick={onClick}>
          <Icon iconName="add" className="mr-1" />
          New Adjustment
        </Button>
      </>
    )
  );
};

export default AdjustmentCreateAction;
